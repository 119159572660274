<div class="flex formgrid grid">
    <div class="col-12 pt-5 flex-grow-1">
        <div class="card">
            <div *ngFor="let variant of category.variants.items; last as isLast">
                <!-- div class="col-12 md:col-1 align-items-center justify-content-center" -->
                    <div class="col-12">
                        <i class="pi pi-trash" style="font-size: 1.5rem" (click)="confirmDeleteVariant(null, variant)"></i>
                        Variant {{usesByLabelInEn[variant.id]}}
                    </div>
                    <div class="col-12 pt-5">
                        <span>
                            <label [for]="'labelUsesC_'+variant.id">Use this label on:</label>
                            <p-multiSelect [id]="'labelUsesC_'+variant.id"
                                           [options]="availableUseOptionsByVariant[variant.id]" [(ngModel)]="variant.uses" appendTo="body"
                                           [style]="{'width':'100%'}" class="text-base text-color"
                                           (onBlur)="recalculateAvailableUses()" (onSelectAllChange)="recalculateAvailableUses()" (onChange)="recalculateAvailableUses()"></p-multiSelect>
                        </span>
                    </div>
                    <div class="col-12 pt-5">
                        <span>
                            <label [for]="'labelEnUsLabelC_'+variant.id">Label</label>
                            <input
                                class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                                type="text" [id]="'labelEnUsLabelC_'+variant.id" pInputText
                                [(ngModel)]="variant.enUsLabel">
                        </span>
                    </div>
                    <div class="col-12 pt-5">
                        <span>
                            <label [for]="'labelEnUsHelpC_'+variant.id">Help Text</label>
                            <input
                                class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                                type="text" [id]="'labelEnUsHelpC_'+variant.id" pInputText
                                [(ngModel)]="variant.enUsHelp">
                        </span>
                    </div>
                    <div class="field col-6">
                        <span>
                            <label [for]="'cprojectDescriptorType_'+variant.id">UI Component Type</label>
                            <p-dropdown [id]="'cprojectDescriptorType_'+variant.id"
                                [options]="projectDescriptorTypes" [(ngModel)]="variant.questionType" appendTo="body"
                                autoWidth="false" [style]="{'width':'100%'}" class="text-base text-color"></p-dropdown>
                        </span>
                    </div>
                    <div class="field col">
                        <span>
                            <label [for]="'cIcon_'+variant.id">Icon</label>
                            <icon-selector [id]="'cIcon_'+variant.id"
                                   [(ngModel)]="variant.tagIcon" [style]="{'width':'100%'}" class="text-base text-color"></icon-selector>
                        </span>
                    </div>
                <!-- /div -->
                <!-- p-divider class="pt-5"></p-divider --><hr/>
                <div *ngIf="isLast">
                    <button [disabled]="allUsesCovered" pButton pRipple [label]="allUsesCovered?'All Uses are Covered':'Add New Variant'" icon="pi pi-plus" class="col-offset-3 col-6 md:col-offset-4 md:col-4" (click)="addVariant()"></button>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 pt-5 formgrid grid">
        <div class="field col-12">
            <span>
                <label [for]="'cprojectDescriptorUses_'+category.id">Show on (screens)</label>
                <p-multiSelect [id]="'cprojectDescriptorUses_'+category.id"
                               [options]="projectDescriptorUses" [(ngModel)]="category.uses" appendTo="body"
                                [style]="{'width':'100%'}" class="text-base text-color" (onSelectAllChange)="recalculateAvailableUses()" (onChange)="recalculateAvailableUses()"></p-multiSelect>
            </span>
        </div>
        <div class="field col-6">
            <span>
                <label [for]="'cbetaReaderMatchUse_'+category.id">Beta Reader Algorithm Setting</label>
                <p-dropdown [id]="'betaReaderMatchUse_'+category.id"
                               [options]="betaReaderMatchUses" [(ngModel)]="category.betaReaderMatchUse" appendTo="body"
                               [style]="{'width':'100%'}" class="text-base text-color"></p-dropdown>
            </span>
        </div>
    </div>
</div>
