import Quill, {Parchment} from "quill";
import { EmbedBlot, Root } from 'parchment';
import {EditorComponent} from "../editor/editor.component";
import {CommentBlot} from "../comment/CommentBlot";
import {RNGComments} from "../comment/RNG_Comments";
import {TableOfContents} from "../../service/document.service";

export class TableOfContentsBlot extends EmbedBlot {

    static override className = 'ql-tocblot';
    static override blotName = 'toc';
    static override tagName = 'span';

    public toc: TableOfContents;

    constructor(scroll: Root, domNode) {
        super(scroll, domNode);

        this.toc = JSON.parse(domNode.getAttribute('toc-data'));
    }

    static buildTOC(value: string, node) {
        const toc = JSON.parse(value);

        node.setAttribute('toc-data', JSON.stringify(toc));
        node.id = 'RNGTOC';
        node.classList.add('rng-toc');
        node.classList.add('no-reveal-codes');
        node.innerText = value;
    }

    static override create(value) {
        let node = super.create(value);

        TableOfContentsBlot.buildTOC(JSON.stringify(value), node);
        return node;
    }

    static override value(node) {
        return JSON.parse(node.getAttribute('toc-data'));
    }

}


