import {
  Column,
  Entity,
  Index,
  JoinColumn,
  ManyToOne,
  OneToMany,
  PrimaryGeneratedColumn,
} from "typeorm";
import { BetaReaderProfileSelection } from "./BetaReaderProfileSelection";
import { ProjectDescriptorCategory } from "./ProjectDescriptorCategory";
import { ProjectProfileSelection } from "./ProjectProfileSelection";

@Index("project_descriptor_pkey", ["id"], { unique: true })
@Entity("project_descriptor")
export class ProjectDescriptor {
  @PrimaryGeneratedColumn({ type: "bigint", name: "id" })
  id!: string;

  @Column("text", { name: "en_us_label" })
  enUsLabel!: string;

  @Column("text", { name: "en_us_help", nullable: true })
  enUsHelp!: string | null;

  @Column("integer", { name: "display_order", default: () => "0" })
  displayOrder!: number;

  @Column("timestamp with time zone", { name: "deleted_at", nullable: true })
  deletedAt!: Date | null;

  @Column("text", { name: "deleted_by", nullable: true })
  deletedBy!: string | null;

  @OneToMany(
    () => BetaReaderProfileSelection,
    (betaReaderProfileSelection) =>
      betaReaderProfileSelection.projectDescriptor,
    { lazy: true }
  )
  betaReaderProfileSelections!: Promise<BetaReaderProfileSelection[]>;

  @ManyToOne(
    () => ProjectDescriptorCategory,
    (projectDescriptorCategory) => projectDescriptorCategory.projectDescriptors,
    { lazy: true }
  )
  @JoinColumn([{ name: "category_id", referencedColumnName: "id" }])
  category!: Promise<ProjectDescriptorCategory>;

  @OneToMany(
    () => ProjectProfileSelection,
    (projectProfileSelection) => projectProfileSelection.projectDescriptor,
    { lazy: true }
  )
  projectProfileSelections!: Promise<ProjectProfileSelection[]>;
}
