import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CheckboxModule} from "primeng/checkbox";
import {TooltipModule} from "primeng/tooltip";
import {NgClass, NgForOf, NgIf} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {InputTextModule} from "primeng/inputtext";
import {PaginatorModule} from "primeng/paginator";
import {ProjectDescriptor, ProjectDescriptorCategory, ProjectDescriptorCategoryVariant} from "../../../../../API";
import {FieldsetModule} from "primeng/fieldset";
import {SplitButtonModule} from "primeng/splitbutton";
import {
    ProjectDescriptorComponent
} from "../../project-descriptor-entries/project-descriptor/project-descriptor.component";
import {
    ProjectDescriptorEditorComponent
} from "../../project-descriptor-entries/project-descriptor-editor/project-descriptor-editor.component";
import {
    ProjectDescriptorCategoryEditorComponent
} from "../category-editor/project-descriptor-category-editor.component";
import {updateProjectDescriptor} from "../../../../../graphql/mutations";
import {ConfirmationService, MenuItem, MenuItemCommandEvent, MessageService} from "primeng/api";
import {ProjectDescriptorUse} from "../../../../api/enums";
import {MenuModule} from "primeng/menu";
import {OrderListModule} from "primeng/orderlist";
import {ProgressService} from "../../../../service/progress.service";
import {getVariant} from "../../../../utils";
import {
    DataService
} from "../../../../service/data.service";

@Component({
  selector: 'project-descriptor-category',
  standalone: true,
    imports: [
        CheckboxModule,
        TooltipModule,
        NgIf,
        FormsModule,
        InputTextModule,
        PaginatorModule,
        FieldsetModule,
        NgForOf,
        SplitButtonModule,
        ProjectDescriptorComponent,
        ProjectDescriptorEditorComponent,
        ProjectDescriptorCategoryEditorComponent,
        MenuModule,
        OrderListModule,
        NgClass
    ],
  templateUrl: './project-descriptor-category.component.html',
  styleUrl: './project-descriptor-category.component.scss'
})

export class ProjectDescriptorCategoryComponent implements OnInit {
    @Input() category!: ProjectDescriptorCategory;
    @Input() editable: boolean = false;
    @Input() namespace: string;
    @Input() use: ProjectDescriptorUse = null;

    protected editing: boolean = false;
    protected reorder: boolean = false;
    protected editMenuItems: MenuItem[] = [
        { label: 'Edit This Category', icon: 'pi pi-fw pi-pencil', command: () => this.editing=true},
        { separator: true },
        { label: 'Add New Item to this Category', icon: 'pi pi-fw pi-plus', command: () => this.addProjectDescriptor()},
        { label: 'Reorder Items in this Category', icon: 'pi pi-fw pi-sort-alt', command: () => { this.reorder = true; }}
    ];
    protected saveMenuItems: MenuItem[] = [
        { label: 'Undo Changes and Cancel Editing', icon: 'pi pi-fw pi-undo', command: (e) => this.cancelChanges(e) },
        { separator: true },
        { label: 'Permanently Delete this Item', icon: 'pi pi-fw pi-trash', command: (e) => this.confirmDelete(e) }
    ];

    constructor(private progressService: ProgressService, private confirmationService: ConfirmationService, private messageService: MessageService, private dataService: DataService) {
    }

    ngOnInit(): void {
        //console.log('In category component: ', this.category);
    }

    confirmDelete(event: MenuItemCommandEvent) {
        this.confirmationService.confirm({
            key: `confirmDelete`,
            target: new EventTarget,
            message: 'Are you sure that you want to proceed?',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'Removing Category from All Projects' });
                this.dataService.removeCategory(this.category);
            }
        });
    }

    private async cancelChanges(event: MenuItemCommandEvent) {
        this.progressService.blockWithMessage('Cancelling Changes');
        const ret = await this.dataService.pullCategory(this.category.id);
        this.category.betaReaderMatchUse = ret.betaReaderMatchUse;
        this.category.displayOrder = ret.displayOrder;
        this.category.variants = ret.variants;
        this.editing = false;
        this.progressService.unBlock();
    }

    async addProjectDescriptor() {
        this.progressService.blockWithMessage('Adding Item')
        const id = await this.dataService.addEmpty(this.category);
        this.progressService.unBlock();
    }

    async updateCategory(category: ProjectDescriptorCategory) {
        await this.dataService.saveCategory(this.category);
    }

    async saveAfterReorder() {
        this.progressService.blockWithMessage('Updating Item Order')
        await this.dataService.saveAfterReorder(this.category);
        this.progressService.unBlock();
    }

    protected readonly getVariant = getVariant;
}
