<div class="card mb-0">
    <div class="flex justify-content-between mb-3">
        <div>
            <span class="block text-500 font-medium mb-3">My Word Count</span>
            <div class="text-900 font-medium text-xl">{{wordCount}}</div>
        </div>
        <div class="flex align-items-center justify-content-center bg-blue-100 border-round" [ngStyle]="{width: '2.5rem', height: '2.5rem'}">
            <i class="pi pi-shopping-cart text-blue-500 text-xl"></i>
        </div>
    </div>
    <span class="text-green-500 font-medium">Coming Soon</span>
    <span class="text-500"></span>
</div>
