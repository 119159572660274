import {Injectable, signal, WritableSignal} from '@angular/core';
import {BehaviorSubject, firstValueFrom, Observable, Subscription} from "rxjs";
import {
    PlatformRole,
} from "../api/enums";
import {v4 as uuid} from "uuid";
import {activateSignalDebugger, ErrorService, LogProvider} from "./error.service";
import {GatherDebugData} from "./gather-debug-data";
import {Delta} from "quill/core";
import {DocumentSection} from "./db/entities/DocumentSection";
import {Project} from "./db/entities/Project";
import {StorageMap} from "@ngx-pwa/local-storage";
import {ORM} from "../utils";
import {ProjectSchema} from "../api/project_schemas";
import {toObservable} from "@angular/core/rxjs-interop";
import {EditorComponent} from "../components/editor/editor.component";
import {BetaReaderProfile} from "../../API";

@Injectable({
    providedIn: 'root'
})
export class DocumentService implements GatherDebugData {

    private readonly LOG = LogProvider.getLogger('DocumentService');

    private hydration = undefined;

    selectedProject: WritableSignal<Project> = signal(null); // Filled on editorCreate
    selectedProjectObs: Observable<Project> = toObservable(this.selectedProject);

    private lazyDocumentSectionContent: Map<string, SectionContent> = new Map<string, SectionContent>();

    currentSectionContent: WritableSignal<SectionContent> = signal(null); // Filled on editorCreate
    tableOfContents: WritableSignal<TableOfContents> = signal(null); // Filled on editorCreate
    private _tableOfContentsObs: BehaviorSubject<TableOfContents> = new BehaviorSubject(this.tableOfContents());
    public readonly tableOfContentsObs: Observable<TableOfContents> = this._tableOfContentsObs.asObservable();

    private tableOfContentsOrderedList: Section[];

    //TODO: We'll need a push mechanism of some sort for multi-user updates - WebSocket or something.
    //TODO:   Also needs mechanism to lock the document to use offline?

    constructor(
        private storage: StorageMap,
        private errorService: ErrorService) {

        errorService.registerServiceForDebug('DocumentService', this);
        activateSignalDebugger<Project>(this.selectedProject, 'DocumentService.selectedProject');
        activateSignalDebugger<SectionContent>(this.currentSectionContent, 'DocumentService.currentSectionContent');
        activateSignalDebugger<TableOfContents>(this.tableOfContents, 'DocumentService.tableOfContents');

    }

    private async checkTOC(): Promise<TableOfContents> {
        // Check if the current version is the latest - this means checking remote cache for multi-user
        const toc = this.tableOfContents();

        //Since we are assuming local, for now - we are done.
        return toc;
    }

    public async getDocumentSectionContent(section: Section, loadAdjacent: boolean = true): Promise<SectionContent> {
        if (!this.lazyDocumentSectionContent.has(section.uuid)) {
            if (await firstValueFrom(this.storage.has('DSC_' + section.uuid))) {
                const loaded = await firstValueFrom(this.storage.get('DSC_' + section.uuid));
                this.LOG.debug('Pulled from local storage: ', loaded);
                //Yes - let's grab it
                const sc: SectionContent = JSON.parse(loaded?.toString());

                if (sc) {
                    //Work around an odd one-off from saving a bunch of these wrong early on
                    if (!sc.delta.ops) {
                        this.LOG.debug('Content for ' + sc.section.uuid + ' needs reparse: ', sc.delta);
                        //Reparse
                        sc.delta = JSON.parse(sc.delta.toString());
                        this.LOG.debug('Content for ' + sc.section.uuid + ' reparsed: ', sc.delta);
                    }

                    this.lazyDocumentSectionContent.set(section.uuid, sc);
                }
            }
        }
        if (!this.lazyDocumentSectionContent.has(section.uuid)) {
            //We don't have it - go get it
            const documentSectionRepository = (await ORM.getORMDatasource()).getRepository(DocumentSection);
            const documentSection: DocumentSection = await documentSectionRepository.findOneBy({
                uuid: section.uuid
            });
            this.LOG.debug('DocumentSection: ', documentSection);
            if (!documentSection) {
                this.errorService.handleError({
                    detail: "Document section with UUID: " + uuid + " requested, and doesn't exist.",
                    severity: "ERROR",
                    stack: undefined,
                    summary: "Document section with UUID: " + uuid + " requested, and doesn't exist.",
                });
                return null;
            }
            section.sectionVersion = documentSection.version;

            const sc: SectionContent = {
                section: section,
                delta: documentSection.content
            };

            this.lazyDocumentSectionContent.set(section.uuid, sc);
            //Save to local cache
            this.storage.set('DSC_' + section.uuid,
                JSON.stringify(sc)
            ).subscribe();
        }
        if (loadAdjacent) {
            //Make sure the sections on either side are already loaded - async - no need to wait for it.
            this.getPreviousSection(section)
                .then(s => {
                    if (s) {
                        this.getDocumentSectionContent(s, false);
                    }
                });
            this.getNextSection(section)
                .then(s => {
                    if (s) {
                        this.getDocumentSectionContent(s, false);
                    }
                });
        }
        return this.lazyDocumentSectionContent.get(section.uuid);
    }

    private async buildTOCOrderedList() {
        const toc = this.tableOfContents();
        this.tableOfContentsOrderedList = [toc.topLevelSectionLink.section];
        this.walkTOCInOrder(toc.topLevelSectionLink, this.tableOfContentsOrderedList);
    }

    private walkTOCInOrder(start: SectionLink, out: Section[]) {
        if (start.childLinks?.length > 0) {
            for (const sl of start.childLinks) {
                out.push(sl.section);
                this.walkTOCInOrder(sl, out);
            }
        }
    }

    public async switchToNextSection() {
        this.switchToSection(await this.getNextSection(this.currentSectionContent().section));
    }

    public async switchToPreviousSection() {
        this.switchToSection(await this.getPreviousSection(this.currentSectionContent().section));
    }

    async switchToSection(section: Section) {
        const sectionContent = await this.getDocumentSectionContent(section);
        this.currentSectionContent.set(sectionContent);
        await EditorComponent.displayContent(this.currentSectionContent().delta);
    }

    private async getPreviousSection(section: Section): Promise<Section> {
        if (!this.tableOfContentsOrderedList) {
            await this.buildTOCOrderedList();
        }
        const currentIdx = this.tableOfContentsOrderedList.indexOf(section);
        if (currentIdx < 1) {
            return null;
        }
        return this.tableOfContentsOrderedList.at(currentIdx - 1);
    }

    private async getNextSection(section: Section): Promise<Section> {
        if (!this.tableOfContentsOrderedList) {
            await this.buildTOCOrderedList();
        }
        const currentIdx = this.tableOfContentsOrderedList.indexOf(section);
        if (currentIdx >= this.tableOfContentsOrderedList.length) {
            return null;
        }
        return this.tableOfContentsOrderedList.at(currentIdx + 1);
    }

    public async updateDocumentSectionContent(contents: Delta, section: Section = this.currentSectionContent().section) {
        const sc: SectionContent = {
            section: section,
            delta: contents
        };

        this.lazyDocumentSectionContent.set(section.uuid, sc);

        //Save to local cache
        this.storage.set('DSC_' + section.uuid, JSON.stringify(sc)).subscribe();

        //Push content remotely
        const documentSectionRepository = (await ORM.getORMDatasource()).getRepository(DocumentSection);
        const result1 = await documentSectionRepository.update(
            {
                uuid: section.uuid,
            },
            {
                content: contents
            }
        );
        this.LOG.debug('Updated Section: ', result1);
        if (result1.affected === 0) {
            await this.createDocumentSectionContent(section, contents);
        }
    }

    public async createDocumentSectionContent(section: Section, contents: Delta): Promise<SectionContent> {
        const sc: SectionContent = {
            section: section,
            delta: contents
        };

        this.LOG.debug('Contents on create: ', contents);

        this.lazyDocumentSectionContent.set(section.uuid, sc);

        //Save to local cache
        this.storage.set('DSC_' + section.uuid, JSON.stringify(sc)).subscribe();

        //Push content remotely
        const documentSectionRepository = (await ORM.getORMDatasource()).getRepository(DocumentSection);
        let dsr: DocumentSection = documentSectionRepository.create();
        dsr.content = contents;
        dsr.project = Promise.resolve(this.selectedProject());
        dsr.uuid = section.uuid;
        documentSectionRepository.save(dsr).then();
        return sc;
    }

    getDebugInfo() {
        return {

            // writerProjectDescriptorCategories: this.writerProjectDescriptorCategories,
            // betaReaderViewOfProjectDescriptorCategories: this.betaReaderViewOfProjectDescriptorCategories
        };
    }

    async saveTableOfContents(toc: TableOfContents) {
        this.LOG.debug("Saving TOC: ",toc);
        const tocStr = JSON.stringify(toc);
        try {
            const proj = this.selectedProject();
            /*if (proj.currentTableOfContents && proj.currentTableOfContents === toc) {
                //No Need to save
                return;
            }*/
            //Save to local cache
            this.storage.set('TOC_' + toc.topLevelSectionLink.section.uuid, tocStr).subscribe();

            this.tableOfContents.set(toc);
            this._tableOfContentsObs.next(toc);

            const projectRepository = (await ORM.getORMDatasource()).getRepository(Project);
            await projectRepository.update(
                {
                    uuid: proj.uuid,
                },
                {
                    currentTableOfContents: toc
                }
            );
            proj.currentTableOfContents = toc;
            this.selectedProject.set(proj);
        } catch (e) {
            this.errorService.handleError({
                severity: 'error',
                summary: 'Error',
                detail: 'Unable to update Table of Contents. See log for details',
                error: e,
                stack: e.stack
            });
            return;
        }
    }

    async setSelectedProject(selectedProject: Project) {
        this.LOG.debug("Set Selected Project: ", selectedProject);
        this.tableOfContents.set(selectedProject.currentTableOfContents);
        this._tableOfContentsObs.next(selectedProject.currentTableOfContents);
        this.selectedProject.set(selectedProject);
        await this.checkTOC();
    }

    //This returns the SectionLink that contains this child - or null if none
    getParentSectionLinkFromTOC(child: Section, level: number = null): {
        sectionLink: SectionLink,
        childIndex: number
    } {
        const toc = this.tableOfContents().topLevelSectionLink;
        if (child.uuid === toc.section.uuid) {
            return null; //No parent - this is the top
        }
        return this.getParentSectionLink(child, toc, level);
    }

    private getParentSectionLink(child: Section, possibleParent: SectionLink, level: number): {
        sectionLink: SectionLink,
        childIndex: number
    } {

        const idx = possibleParent.childLinks.findIndex(sl => sl.section.uuid === child.uuid);
        if (idx > -1) {
            if (!level || possibleParent.section.level === level) {
                return {sectionLink: possibleParent, childIndex: idx}; //Found it, so this is the parent group; (And it is the right level)
            } else {
                return this.getParentSectionLinkFromTOC(possibleParent.section, level); //Use this found parent as the new child and see if it has the right level as parent
            }
        }
        for (let link of possibleParent.childLinks) {
            const sl = this.getParentSectionLink(child, link, level);
            if (sl) {
                //Found it - let it bubble up
                return sl;
            }
        }
        return null; //Not on this branch
    }

    getSectionLinkFromTOC(child: Section): { sectionLink: SectionLink, childIndex: number } {
        const toc = this.tableOfContents().topLevelSectionLink;
        if (child.uuid === toc.section.uuid) {
            return {sectionLink: toc, childIndex: 0}; //No parent - this is the top - return it
        }
        return this.getSectionLink(child, toc);
    }

    private getSectionLink(child: Section, possibleParent: SectionLink): {
        sectionLink: SectionLink,
        childIndex: number
    } {

        const idx = possibleParent.childLinks.findIndex(sl => sl.section.uuid === child.uuid);
        if (idx > -1) {
            return {sectionLink: possibleParent.childLinks[idx], childIndex: idx}; //Found it, so this is the parent group - return the child;
        }
        for (let link of possibleParent.childLinks) {
            const sl = this.getSectionLink(child, link);
            if (sl) {
                //Found it - let it bubble up
                return sl;
            }
        }
        return null; //Not on this branch
    }

    async getProjectForUUID(uuid: string): Promise<Project> {
        const projectRepository = (await ORM.getORMDatasource()).getRepository(Project);
        return projectRepository.findOneBy({
            uuid: uuid
        });
    }
}

export class SectionLink {
    section: Section;
    childLinks: SectionLink[];
}

export class TableOfContents {
    topLevelSectionLink: SectionLink;
    projectSchema: ProjectSchema;
    schemaVersion: number;
    documentUpdater: string;
    documentVersion: number;
    documentTimestamp: Date;
}

export class Section {
    uuid: string;
    level: number;
    name: string | null;
    metadata: { key: string, value: string }[];
    sectionVersion: number;
}

export class Caret {
    userID: string;
    sectionUUID: string;
    displayName: string;
    startSelection: number;
    selectionLength: number;
}

export type SectionContent = {
    section: Section;
    delta: Delta;
}

