import {
  Column,
  Entity,
  Index,
  JoinColumn,
  OneToMany,
  OneToOne,
  PrimaryGeneratedColumn,
} from "typeorm";
import { Project } from "./Project";
import { PlatformUser } from "./PlatformUser";

@Index("writer_profile_pkey", ["id"], { unique: true })
@Index("writer_profile_unique_idx", ["platformUserId"], { unique: true })
@Entity("writer_profile")
export class WriterProfile {
  @PrimaryGeneratedColumn({ type: "bigint", name: "id" })
  id!: string;

  @Column("bigint", { name: "platform_user_id" })
  platformUserId!: string;

  @Column("text", { name: "bio_sketch", nullable: true })
  bioSketch!: string | null;

  @OneToMany(() => Project, (project) => project.writerProfile, { lazy: true })
  projects!: Promise<Project[]>;

  @OneToOne(() => PlatformUser, (platformUser) => platformUser.writerProfile, {
    lazy: true,
  })
  @JoinColumn([{ name: "platform_user_id", referencedColumnName: "id" }])
  platformUser!: Promise<PlatformUser>;
}
