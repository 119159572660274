import {
  Column,
  Entity,
  Index,
  JoinColumn,
  ManyToOne,
  PrimaryGeneratedColumn,
} from "typeorm";
import { BetaReaderProfile } from "./BetaReaderProfile";
import { Project } from "./Project";

@Index("beta_reader_project_pkey", ["id"], { unique: true })
@Index("beta_reader_project_uuid", ["uuid"], { unique: true })
@Entity("beta_reader_project")
export class BetaReaderProject {
  @PrimaryGeneratedColumn({ type: "bigint", name: "id" })
  id!: string;

  @Column("text", { name: "beta_reader_status", nullable: true })
  betaReaderStatus!: string | null;

  @Column("text", { name: "writer_status", nullable: true })
  writerStatus!: string | null;

  @Column("uuid", { name: "uuid", default: () => "gen_random_uuid()" })
  uuid!: string;

  @Column("integer", { name: "color_index", default: () => "1" })
  colorIndex!: number;

  @ManyToOne(
    () => BetaReaderProfile,
    (betaReaderProfile) => betaReaderProfile.betaReaderProjects,
    { lazy: true }
  )
  @JoinColumn([{ name: "beta_reader_profile_id", referencedColumnName: "id" }])
  betaReaderProfile!: Promise<BetaReaderProfile>;

  @ManyToOne(() => Project, (project) => project.betaReaderProjects, {
    lazy: true,
  })
  @JoinColumn([{ name: "project_id", referencedColumnName: "id" }])
  project!: Promise<Project>;
}
