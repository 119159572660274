import Quill, {Parchment} from "quill";
import {Comment, CommentView, Reply, RNGComments} from "./RNG_Comments";
import Inline from "quill/blots/inline";
import {Root} from "parchment";
import {EditorComponent} from "../editor/editor.component";

export class CommentBlot extends Inline {

    private comment: Comment;

    private iconSpan: HTMLSpanElement;

    static override create(value) {
        let node = super.create(value);

        CommentBlot.buildComment(value, node);
        return node;
    }

    constructor(scroll: Root, domNode) {
        super(scroll, domNode);

        this.comment = JSON.parse(domNode.getAttribute('comment-data'));

        //Number of Unseen

        this.iconSpan = document.createElement('span');
        this.iconSpan.classList.add('comment-icon');
        this.iconSpan.setAttribute('contenteditable', 'false');
        this.iconSpan.addEventListener('click', function(e) {
            // e.target should always be node since it is attached to node
            if ( !RNGComments.isEnabled ) {
                return;
            }
            const blot: Parchment.Blot = <Parchment.Blot>Quill.find(domNode);

            const offset = blot.offset(blot.scroll);
            window['angularComponentRef'].zone.run(
                () => {
                    window['angularComponentRef'].showComment(blot, offset);
                }
            );
        });

        this.updateUnseen(); //Display icon and number next to it.

        domNode.appendChild(this.iconSpan);
    }

    private countUnread(uuid: string, replies: Reply[]): number {
        let ret: number = 0;
        if (replies) {
            for (const reply of replies) {
                ret = ret + this.countUnread(uuid, reply.replies);
                if (reply.authorUUID !== uuid && !this.viewedIncludesUUID(uuid,reply.userFirstViews)) {
                    ret++;
                }
            }
        }
        return ret;
    }

    private viewedIncludesUUID(uuid: string, firstViewed: CommentView[]): boolean {
        if (!firstViewed) {
            return false;
        }
        for (const view of firstViewed) {
            if (uuid === view.uuid) {
                return true;
            }
        }
        return false;
    }

    public updateUnseen() {
        const myUUID: string = EditorComponent.platformUser.uuid;
        let unseen: number = 0;
        if (this.comment.authorUUID !== myUUID && !this.viewedIncludesUUID(myUUID,this.comment.userFirstViews)) {
            unseen++;
        }
        unseen = unseen + this.countUnread(myUUID, this.comment.replies);
        this.iconSpan.innerHTML = this.comment.iconHTML+((unseen > 0)?'<mark style="background-color: white">'+unseen+'</mark>':'');


    }

    /*static override value(node) {
        console.log('NODE: ', node);
        console.log('NODE DATA: ', node.getAttribute('data'));
        return JSON.parse(node.getAttribute('data'));
    }*/

    static commentOn(comment: Comment, node) {
        node.classList.remove('comment-off');
    }

    static commentOff(comment: Comment, node) {
        node.classList.add('comment-off');

    }

    static toggleComment(enable: boolean, comment: Comment, node) {
        if (enable) {
            this.commentOn(comment, node);
        } else {
            this.commentOff(comment, node);
        }
    }

    static buildComment(value: string, node) {
        const comment = JSON.parse(value);
        //Old style?
        if (!comment.iconHTML) {
            comment.iconHTML = (comment.icon === 'beta-reader') ? '<i class="comment-icon pi pi-comment"></i>' :
                (comment.icon === 'author') ? '<i class="comment-icon pi pi-pencil"></i>' :
                    'error' //No Role Project
        }
        node.setAttribute('comment-data', JSON.stringify(comment));
        node.id = 'RNGCOMMENT-'+comment.uuid;
        node.classList.add('rng-comment');
        node.classList.add('comment-author-'+comment.authorUUID);
        node.classList.add('comment-color-'+comment.colorIndex);

    }

    static override formats(domNode) {
        return domNode.getAttribute('comment-data') || true;
    }

    override format(name, value) {
        if (name !== this.statics.blotName || !value) {
            super.format(name, value);
        } else {
            CommentBlot.buildComment(value, this.domNode);
        }
    }

    override formats() {
        let formats = super.formats();
        formats['comment'] = CommentBlot.formats(this.domNode);
        return formats;
    }
}

CommentBlot.blotName = 'comment';
CommentBlot.className = 'ql-commentblot';
CommentBlot.tagName = 'mark';
