import {
  Column,
  Entity,
  Index,
  JoinColumn,
  ManyToOne,
  PrimaryGeneratedColumn,
} from "typeorm";
import { BetaReaderProfile } from "./BetaReaderProfile";
import { ProjectDescriptor } from "./ProjectDescriptor";

@Index(
  "beta_reader_profile_descriptor_profile_idx",
  ["betaReaderProfileId", "projectDescriptorId"],
  { unique: true }
)
@Index("beta_reader_profile_selection_pkey", ["id"], { unique: true })
@Entity("beta_reader_profile_selection")
export class BetaReaderProfileSelection {
  @PrimaryGeneratedColumn({ type: "bigint", name: "id" })
  id!: string;

  @Column("bigint", { name: "beta_reader_profile_id" })
  betaReaderProfileId!: string;

  @Column("bigint", { name: "project_descriptor_id" })
  projectDescriptorId!: string;

  @Column("text", { name: "value" })
  value!: string;

  @ManyToOne(
    () => BetaReaderProfile,
    (betaReaderProfile) => betaReaderProfile.betaReaderProfileSelections,
    { lazy: true }
  )
  @JoinColumn([{ name: "beta_reader_profile_id", referencedColumnName: "id" }])
  betaReaderProfile!: Promise<BetaReaderProfile>;

  @ManyToOne(
    () => ProjectDescriptor,
    (projectDescriptor) => projectDescriptor.betaReaderProfileSelections,
    { lazy: true }
  )
  @JoinColumn([{ name: "project_descriptor_id", referencedColumnName: "id" }])
  projectDescriptor!: Promise<ProjectDescriptor>;
}
