    <div *ngIf="!reorder && editable" class="pb-5">
        <button pButton type="button" icon="pi pi-ellipsis-v" class="p-button-rounded p-button-text p-button-plain" (click)="menu.toggle($event)"></button>
        <p-dropdown optionLabel="name" optionValue="value" [options]="viewDropDown" [(ngModel)]="use"></p-dropdown>
        <p-tieredMenu #menu [popup]="true" [model]="editMenuItems"></p-tieredMenu>
    </div>
    <div *ngIf="reorder">
        <button pButton type="button" icon="pi pi-sort-alt-slash" class="p-button-rounded p-button-text p-button-plain" pTooltip="End reorder and save" (click)="saveAfterReorder()"></button>
    </div>
    <div *ngIf="!reorder">
        <p-dataView [value]="categories">
            <ng-template pTemplate="empty">
                <p-skeleton styleClass="mb-2"></p-skeleton>
                <p-skeleton width="10rem" styleClass="mb-2"></p-skeleton>
                <p-skeleton styleClass="mb-2"></p-skeleton>
                <p-skeleton width="10rem" styleClass="mb-2"></p-skeleton>
            </ng-template>
            <ng-template let-categories pTemplate="list">
                <div class="grid grid-nogutter">
                    <!-- div class="flex flex-wrap p-1 align-items-center gap-3" -->
                    <div class="col-12 pb-3" *ngFor="let c of categories">
                            <project-descriptor-category [namespace]="namespace" [use]="use"  [editable]="editable" [category]="c"></project-descriptor-category>
                    </div>
                </div>
            </ng-template>
        </p-dataView>
    </div>
    <div *ngIf="reorder">
        <p-orderList [value]="categories" [listStyle]="{ height: '25rem' }" [dragdrop]="true">
            <ng-template let-category pTemplate="item">
                <h5>{{getVariant(category, use).enUsHelp}}</h5>
            </ng-template>
        </p-orderList>
    </div>

