import {Component, Input} from '@angular/core';
import {NgStyle} from "@angular/common";


@Component({
    selector: 'placeholder',
    standalone: true,
    imports: [
        NgStyle
    ],
    templateUrl: './placeholder.component.html'
})

export class PlaceholderComponent {

    @Input() icolor!: string;

    constructor() {
    }
}
