<div *ngIf="!editing">
@switch ((variant && variant.questionType) ? variant.questionType : 'CHECKBOXES') {
    @case ("CHECKBOXES") {
        <div class="pt-2 pl-4 w-full">
            <i *ngIf="editable && !editing" class="pi pi-pencil" (click)="editing=true"></i>
            <p-checkbox [id]="'pdCheckbox_'+projectDescriptor.id" [inputId]="'pdCheckbox_'+projectDescriptor.id" (ngModelChange)="dataService.setPDCurrentValueBoolean(namespace, projectDescriptor.id, $event)" [ngModel]="dataService.getPDCurrentValueBoolean(namespace, projectDescriptor.id)" binary="true"></p-checkbox>
            <label [for]="'pdCheckbox_'+projectDescriptor.id"> {{projectDescriptor.enUsLabel}} <i *ngIf="projectDescriptor.enUsHelp" class="pi pi-question-circle" [pTooltip]="projectDescriptor.enUsHelp" style="font-size: 1rem"></i></label>
        </div>
    }
    @case ("RADIO_BUTTONS") {
        <div class="pt-2 pl-4 w-full">
            <i *ngIf="editable && !editing" class="pi pi-pencil" (click)="editing=true"></i>
            <p-radioButton [id]="'pdRadioButton_'+projectDescriptor.id" [inputId]="'pdRadioButton_'+projectDescriptor.id" [name]="descriptorCategory.id.toString(10)" [value]="projectDescriptor.id" (ngModelChange)="dataService.setSingleSelectPDCurrentValue(namespace, descriptorCategory, $event)" [ngModel]="dataService.getSingleSelectPDCurrentValue(namespace, descriptorCategory)"></p-radioButton>
            <label [for]="'pdRadioButton_'+projectDescriptor.id"> {{projectDescriptor.enUsLabel}} <i *ngIf="projectDescriptor.enUsHelp" class="pi pi-question-circle" [pTooltip]="projectDescriptor.enUsHelp" style="font-size: 1rem"></i></label>
        </div>
    }
    @case ("TEXT") {
        <div class="pt-3 pl-4 w-full">
            <span class="p-float-label">
                <i *ngIf="editable && !editing" class="pi pi-pencil" (click)="editing=true"></i>
                <input [pTooltip]="projectDescriptor.enUsLabel" tooltipPosition="top"
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                    type="text" [id]="'pdText_'+projectDescriptor.id" pInputText (ngModelChange)="dataService.setPDCurrentValueString(namespace, projectDescriptor.id, $event)" [ngModel]="dataService.getPDCurrentValueString(namespace, projectDescriptor.id)">
                <label [for]="'pdText_'+projectDescriptor.id"> {{projectDescriptor.enUsLabel}} <i *ngIf="projectDescriptor.enUsHelp" class="pi pi-question-circle" [pTooltip]="projectDescriptor.enUsHelp" style="font-size: 1rem"></i></label>
            </span>
        </div>
    }
    @case ("LONGTEXT") {
        <div class="pt-3 pl-4 w-full">
            <span>
                <i *ngIf="editable && !editing" class="pi pi-pencil" (click)="editing=true"></i>
                <label [for]="'pdTextArea_'+projectDescriptor.id"> {{projectDescriptor.enUsLabel}} <i *ngIf="projectDescriptor.enUsHelp" class="pi pi-question-circle" [pTooltip]="projectDescriptor.enUsHelp" style="font-size: 1rem"></i></label>
                <textarea rows="6" [pTooltip]="projectDescriptor.enUsHelp" tooltipPosition="top"
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                      type="text" [id]="'pdTextArea_'+projectDescriptor.id" pInputTextArea (ngModelChange)="dataService.setPDCurrentValueString(namespace, projectDescriptor.id, $event)" [ngModel]="dataService.getPDCurrentValueString(namespace, projectDescriptor.id)"></textarea>
            </span>
        </div>
    }
    @case ("NUMBER") {
        <div class="pt-3 pl-4 w-full">
            <span class="p-float-label">
                 <i *ngIf="editable && !editing" class="pi pi-pencil" (click)="editing=true"></i>
                 <p-inputNumber [pTooltip]="projectDescriptor.enUsHelp" tooltipPosition="top"
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                    type="text" [id]="'pdNumber_'+projectDescriptor.id" (ngModelChange)="dataService.setPDCurrentValueNumber(namespace, projectDescriptor.id, $event)" [ngModel]="dataService.getPDCurrentValueNumber(namespace, projectDescriptor.id)"></p-inputNumber>
                <label [for]="'pdNumber_'+projectDescriptor.id"> {{projectDescriptor.enUsLabel}} <i *ngIf="projectDescriptor.enUsHelp" class="pi pi-question-circle" [pTooltip]="projectDescriptor.enUsHelp" style="font-size: 1rem"></i></label>
            </span>
        </div>
    }
}
</div>
<div *ngIf="editing">
    <project-descriptor-editor [category]="descriptorCategory" [projectDescriptor]="projectDescriptor"></project-descriptor-editor>
    <p-splitButton label="Save" appendTo="body" icon="pi pi-save" (onClick)="updateProjectDescriptor(projectDescriptor)" [model]="editMenuItems"></p-splitButton>
</div>
