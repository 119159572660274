import {Column, Entity, Index, JoinColumn, ManyToOne, VersionColumn} from "typeorm";
import { Project } from "./Project";
import {Delta} from "quill/core";

@Index("document_section_pkey", ["uuid"], { unique: true })
@Entity("document_section")
export class DocumentSection {
  @Column("uuid", { primary: true, name: "uuid" })
  uuid!: string;

  @Column("jsonb", { name: "content" })
  content!: Delta;

  @VersionColumn({ type: "bigint", name: "version", default: 0 })
  version!: number

  @ManyToOne(() => Project, (project) => project.documentSections, {
    lazy: true,
  })
  @JoinColumn([{ name: "project_id", referencedColumnName: "id" }])
  project!: Promise<Project>;
}
