import {
  Column,
  Entity,
  Index,
  JoinColumn,
  ManyToOne,
  PrimaryGeneratedColumn,
} from "typeorm";
import { ProjectDescriptor } from "./ProjectDescriptor";
import { Project } from "./Project";

@Index("project_profile_selection_pkey", ["id"], { unique: true })
@Index(
  "project_profile_selection_prj_desc_idx",
  ["projectDescriptorId", "projectId"],
  { unique: true }
)
@Entity("project_profile_selection")
export class ProjectProfileSelection {
  @PrimaryGeneratedColumn({ type: "bigint", name: "id" })
  id!: string;

  @Column("bigint", { name: "project_id" })
  projectId!: string;

  @Column("bigint", { name: "project_descriptor_id" })
  projectDescriptorId!: string;

  @Column("text", { name: "value", nullable: true })
  value!: string | null;

  @ManyToOne(
    () => ProjectDescriptor,
    (projectDescriptor) => projectDescriptor.projectProfileSelections,
    { lazy: true }
  )
  @JoinColumn([{ name: "project_descriptor_id", referencedColumnName: "id" }])
  projectDescriptor!: Promise<ProjectDescriptor>;

  @ManyToOne(() => Project, (project) => project.projectProfileSelections, {
    lazy: true,
  })
  @JoinColumn([{ name: "project_id", referencedColumnName: "id" }])
  project!: Promise<Project>;
}
