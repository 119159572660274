//Adapted from DynamicQuillTools by T-vk https://github.com/T-vK/DynamicQuillTools/blob/master/DynamicQuillTools.js
// For use with the RNG Scrivendium modified Quill editor

import {RNGQuillToolbarItem} from "./RNGQuillToolbarItem";
import {en} from "@fullcalendar/core/internal-common";


export class RNGQuillToolbarSplitButton extends RNGQuillToolbarItem {

    private id: string;
    private dropDownEl: HTMLSpanElement;
    private ddClickFunction; //Need this so we can remove it and add it back when disabling, etc.
    private buttonClickFunction; //Need this so we can remove it and add it back when disabling, etc.
    private dropDownPickerEl: Element;
    private dropDownPickerLabelEl: HTMLSpanElement;
    private onClick: Function;
    private selectedItem: SplitButtonItem;
    private iconSpan: HTMLSpanElement;
    private items: SplitButtonItem[];
    private enabled: boolean = true;

    /**
     * Creates an instance of QuillToolbarDropDown.
     *
     * @constructor
     * @param {string} id - The id of the quill tool.
     * @param {SplitButtonItem} defaultItem - The default item that is being displayed before making a selection.
     * @param {SplitButtonItem[]} items - The default items this dropdown will have. Needs to be a key-value-object (key=visible label; value=actual value).
     * @param {function(value: {})} onClick - called when the button is clicked - passes in the currently selected value
     * @param {function(value: {})} callback - allows return to calling parent - onClick handler is called as onClick(callback, selection)
     */
    constructor(id: string, defaultItem: SplitButtonItem, items: SplitButtonItem[], onClick: Function, callback: Function) {
        super();

        this.id = id;
        this.onClick = onClick;

        this.dropDownEl = document.createElement("span");
        this.dropDownEl.className = `ql-${this.id} ql-picker`;
        this.qlFormatsEl.appendChild(this.dropDownEl);

        this.dropDownPickerLabelEl = document.createElement("span");
        this.dropDownPickerLabelEl.className = "ql-picker-label";
        this.dropDownEl.appendChild(this.dropDownPickerLabelEl);
        const p = this.dropDownEl;
        this.ddClickFunction = function (e) {
            p.classList.toggle('ql-expanded');
        };
        this.dropDownPickerLabelEl.addEventListener('click', this.ddClickFunction);
        window.addEventListener('click', function (e) {
            if (!p.contains(<Node>e.target)) {
                p.classList.remove('ql-expanded');
            }
        });

        this.dropDownPickerEl = document.createElement("span");
        this.dropDownPickerEl.className = "ql-picker-options";
        this.dropDownEl.appendChild(this.dropDownPickerEl);

        this.iconSpan = document.createElement('span');
        this.iconSpan.className = 'ql-section-icon-span';
        this.iconSpan.style.paddingLeft = '5px';
        this.iconSpan.id = id + '-ICON';
        this.buttonClickFunction = () => {
            this.onClick(callback, this.selectedItem.value);
        };
        this.iconSpan.addEventListener('click', this.buttonClickFunction);
        this.dropDownEl.appendChild(this.iconSpan);
        this.setItems(items);
        this.setSelection(defaultItem);

        this.addCssRule(`
            .ql-snow .ql-picker.ql-${this.id} .ql-picker-label::before, .ql-${this.id} .ql-picker.ql-size .ql-picker-item::before {
                content: attr(data-label);
            }
        `);
    }

    /**
     * Set the items for this dropdown tool.
     *
     * @param {object} items - Needs to be a key-value-object (key=visible label; value=actual value).
     */
    private setItems(items: SplitButtonItem[]) {
        this.items = items;
        for (let i = 0; i < this.items.length; i++) {
            const item = this.items[i];
            const newItemEl = document.createElement("span");
            newItemEl.id = this.id + '_' + i;
            newItemEl.className = "ql-picker-item";
            if (!item.enabled) {
                newItemEl.classList.add('ql-picker-item-disabled');
            }
            newItemEl.innerHTML = item.dropDownLabel;
            newItemEl.setAttribute('data-value', JSON.stringify(item.value));
            newItemEl.addEventListener('click', e => {
                this.dropDownEl.classList.remove('ql-expanded');
                this.setSelection(item);
            });
            this.dropDownPickerEl.appendChild(newItemEl);
        }
    }

    /**
     * Set the label for this dropdown tool and automatically adjust the width to fit the label.
     *
     * @param {{label: string, iconClasses: string[], value: {}}} newItem - The new label that should be set.
     */
    setSelection(newItem: SplitButtonItem) {
        if (!newItem.enabled) {
            const fai = this.getFirstActiveItem();
            if (fai) {
                this.setSelection(fai);
            } else {
                this.updateEnabled();
            }
        } else if (!this.enabled) {
            this.updateEnabled();
        }
        const requiredWidth = `${this.getTextWidth(newItem.label) + 15}px`;
        this.dropDownPickerLabelEl.style.width = requiredWidth;
        this.dropDownPickerLabelEl.setAttribute('data-label', newItem.label);
        this.iconSpan.innerHTML = newItem.iconHTML;
        this.selectedItem = newItem;
    }

    pickerLabelClickFuntion = function (e) {
        this.dropDownEl.classList.toggle('ql-expanded');
    }

    private updateEnabled() {
        this.dropDownEl.className = `ql-${this.id} ql-picker`;
        this.iconSpan.className = 'ql-section-icon-span';
        this.dropDownPickerLabelEl.className = "ql-picker-label";
        const nothingAvailable = this.getFirstActiveItem() == null;
        if ((!this.enabled) || nothingAvailable) {
            this.dropDownEl.classList.add('ql-picker-disabled');
            this.iconSpan.classList.add('ql-picker-disabled');
            this.dropDownPickerLabelEl.classList.add('ql-picker-disabled');
        }
        if (this.enabled && !nothingAvailable) {
            this.dropDownPickerLabelEl.addEventListener('click', this.ddClickFunction);
            this.iconSpan.addEventListener('click', this.buttonClickFunction);
        } else {
            const p = this.dropDownEl;
            this.dropDownPickerLabelEl.removeEventListener('click', this.ddClickFunction);
            this.iconSpan.removeEventListener('click', this.buttonClickFunction);
        }
    }

    setEnabled(enabled: boolean) {
        this.enabled = enabled;
        this.updateEnabled();
    }

    getFirstActiveItem(): SplitButtonItem {
        const firstActiveItem = this.items.find(j => j.enabled);
        if (!firstActiveItem) {
            return null;
        } else {
            return firstActiveItem;
        }
    }

    setItemEnabled(item: SplitButtonItem, enabled: boolean) {
        let count = 0;
        for (const i of this.items) {
            if (i.label === item.label) {
                i.enabled = enabled;
                const el = document.getElementById(this.id + '_' + count);
                el.className = "ql-picker-item";
                if (!i.enabled) {
                    el.classList.add('ql-picker-item-disabled');
                }
                if (this.selectedItem.label === item.label && enabled) {
                    this.setSelection(item);
                    this.setEnabled(true);
                }
                if (this.selectedItem.label === item.label && !enabled) {
                    const fai = this.getFirstActiveItem();
                    if (fai) {
                        this.setSelection(fai);
                    } else {
                        this.setEnabled(false);
                    }
                }
                this.updateEnabled();
                return;
            }
            count++;
        }
    }
}

export type SplitButtonItem = {
    label: string,
    dropDownLabel: string,
    iconHTML: string,
    value: {},
    enabled: boolean
}
