import {Component, forwardRef} from '@angular/core';
import {DropdownModule} from "primeng/dropdown";
import {ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR} from "@angular/forms";
import {NgIf} from "@angular/common";

const noop = () => {
};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => IconSelectorComponent),
    multi: true
};

@Component({
    selector: 'icon-selector',
    standalone: true,
    imports: [
        DropdownModule,
        FormsModule,
        NgIf
    ],
    templateUrl: './icon-selector.component.html',
    styleUrl: './icon-selector.component.scss',
    providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})

export class IconSelectorComponent implements ControlValueAccessor {

    icons: string[] = [
        'pi-align-center',
        'pi-align-justify',
        'pi-align-left',
        'pi-align-right',
        'pi-amazon',
        'pi-android',
        'pi-angle-double-down',
        'pi-angle-double-left',
        'pi-angle-double-right',
        'pi-angle-double-up',
        'pi-angle-down',
        'pi-angle-left',
        'pi-angle-right',
        'pi-angle-up',
        'pi-apple',
        'pi-arrow-circle-down',
        'pi-arrow-circle-left',
        'pi-arrow-circle-right',
        'pi-arrow-circle-up',
        'pi-arrow-down',
        'pi-arrow-down-left',
        'pi-arrow-down-right',
        'pi-arrow-left',
        'pi-arrow-right',
        'pi-arrow-right-arrow-left',
        'pi-arrow-up',
        'pi-arrow-up-left',
        'pi-arrow-up-right',
        'pi-arrows-alt',
        'pi-arrows-h',
        'pi-arrows-v',
        'pi-at',
        'pi-backward',
        'pi-ban',
        'pi-bars',
        'pi-bell',
        'pi-bitcoin',
        'pi-bolt',
        'pi-book',
        'pi-bookmark',
        'pi-bookmark-fill',
        'pi-box',
        'pi-briefcase',
        'pi-building',
        'pi-calculator',
        'pi-calendar',
        'pi-calendar-minus',
        'pi-calendar-plus',
        'pi-calendar-times',
        'pi-camera',
        'pi-car',
        'pi-caret-down',
        'pi-caret-left',
        'pi-caret-right',
        'pi-caret-up',
        'pi-cart-plus',
        'pi-chart-bar',
        'pi-chart-line',
        'pi-chart-pie',
        'pi-check',
        'pi-check-circle',
        'pi-check-square',
        'pi-chevron-circle-down',
        'pi-chevron-circle-left',
        'pi-chevron-circle-right',
        'pi-chevron-circle-up',
        'pi-chevron-down',
        'pi-chevron-left',
        'pi-chevron-right',
        'pi-chevron-up',
        'pi-circle',
        'pi-circle-fill',
        'pi-clock',
        'pi-clone',
        'pi-cloud',
        'pi-cloud-download',
        'pi-cloud-upload',
        'pi-code',
        'pi-cog',
        'pi-comment',
        'pi-comments',
        'pi-compass',
        'pi-copy',
        'pi-credit-card',
        'pi-database',
        'pi-delete-left',
        'pi-desktop',
        'pi-directions',
        'pi-directions-alt',
        'pi-discord',
        'pi-dollar',
        'pi-download',
        'pi-eject',
        'pi-ellipsis-h',
        'pi-ellipsis-v',
        'pi-envelope',
        'pi-eraser',
        'pi-euro',
        'pi-exclamation-circle',
        'pi-exclamation-triangle',
        'pi-external-link',
        'pi-eye',
        'pi-eye-slash',
        'pi-facebook',
        'pi-fast-backward',
        'pi-fast-forward',
        'pi-file',
        'pi-file-edit',
        'pi-file-excel',
        'pi-file-export',
        'pi-file-import',
        'pi-file-pdf',
        'pi-file-word',
        'pi-filter',
        'pi-filter-fill',
        'pi-filter-slash',
        'pi-flag',
        'pi-flag-fill',
        'pi-folder',
        'pi-folder-open',
        'pi-forward',
        'pi-gift',
        'pi-github',
        'pi-globe',
        'pi-google',
        'pi-hashtag',
        'pi-heart',
        'pi-heart-fill',
        'pi-history',
        'pi-home',
        'pi-hourglass',
        'pi-id-card',
        'pi-image',
        'pi-images',
        'pi-inbox',
        'pi-info',
        'pi-info-circle',
        'pi-instagram',
        'pi-key',
        'pi-language',
        'pi-link',
        'pi-linkedin',
        'pi-list',
        'pi-lock',
        'pi-lock-open',
        'pi-map',
        'pi-map-marker',
        'pi-megaphone',
        'pi-microphone',
        'pi-microsoft',
        'pi-minus',
        'pi-minus-circle',
        'pi-mobile',
        'pi-money-bill',
        'pi-moon',
        'pi-palette',
        'pi-paperclip',
        'pi-pause',
        'pi-paypal',
        'pi-pencil',
        'pi-percentage',
        'pi-phone',
        'pi-play',
        'pi-plus',
        'pi-plus-circle',
        'pi-pound',
        'pi-power-off',
        'pi-prime',
        'pi-print',
        'pi-qrcode',
        'pi-question',
        'pi-question-circle',
        'pi-reddit',
        'pi-refresh',
        'pi-replay',
        'pi-reply',
        'pi-save',
        'pi-search',
        'pi-search-minus',
        'pi-search-plus',
        'pi-send',
        'pi-server',
        'pi-share-alt',
        'pi-shield',
        'pi-shopping-bag',
        'pi-shopping-cart',
        'pi-sign-in',
        'pi-sign-out',
        'pi-sitemap',
        'pi-slack',
        'pi-sliders-h',
        'pi-sliders-v',
        'pi-sort',
        'pi-sort-alpha-down',
        'pi-sort-alpha-down-alt',
        'pi-sort-alpha-up',
        'pi-sort-alpha-up-alt',
        'pi-sort-alt',
        'pi-sort-alt-slash',
        'pi-sort-amount-down',
        'pi-sort-amount-down-alt',
        'pi-sort-amount-up',
        'pi-sort-amount-up-alt',
        'pi-sort-down',
        'pi-sort-numeric-down',
        'pi-sort-numeric-down-alt',
        'pi-sort-numeric-up',
        'pi-sort-numeric-up-alt',
        'pi-sort-up',
        'pi-spinner',
        'pi-star',
        'pi-star-fill',
        'pi-step-backward',
        'pi-step-backward-alt',
        'pi-step-forward',
        'pi-step-forward-alt',
        'pi-stop',
        'pi-stop-circle',
        'pi-stopwatch',
        'pi-sun',
        'pi-sync',
        'pi-table',
        'pi-tablet',
        'pi-tag',
        'pi-tags',
        'pi-telegram',
        'pi-th-large',
        'pi-thumbs-down',
        'pi-thumbs-down-fill',
        'pi-thumbs-up',
        'pi-thumbs-up-fill',
        'pi-ticket',
        'pi-times',
        'pi-times-circle',
        'pi-trash',
        'pi-truck',
        'pi-twitter',
        'pi-undo',
        'pi-unlock',
        'pi-upload',
        'pi-user',
        'pi-user-edit',
        'pi-user-minus',
        'pi-user-plus',
        'pi-users',
        'pi-verified',
        'pi-video',
        'pi-vimeo',
        'pi-volume-down',
        'pi-volume-off',
        'pi-volume-up',
        'pi-wallet',
        'pi-whatsapp',
        'pi-wifi',
        'pi-window-maximize',
        'pi-window-minimize',
        'pi-wrench',
        'pi-youtube'];

    //The internal data model
    private innerValue: any = '';

    //Placeholders for the callbacks which are later providesd
    //by the Control Value Accessor
    private onTouchedCallback: () => void = noop;
    private onChangeCallback: (_: any) => void = noop;

    //get accessor
    get value(): any {
        return this.innerValue;
    };

    //set accessor including call the onchange callback
    set value(v: any) {
        if (v !== this.innerValue) {
            this.innerValue = v;
            this.onChangeCallback(v);
        }
    }

    //Set touched on blur
    onBlur() {
        this.onTouchedCallback();
    }

    //From ControlValueAccessor interface
    writeValue(value: any) {
        if (value !== this.innerValue) {
            this.innerValue = value;
        }
    }

    //From ControlValueAccessor interface
    registerOnChange(fn: any) {
        this.onChangeCallback = fn;
    }

    //From ControlValueAccessor interface
    registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }
}
