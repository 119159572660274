<div class="flex formgrid grid">
    <div class="col-12 pt-4 flex-grow-1">
        <span class="p-float-label">
            <input
                class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                type="text" id="enUsLabel" pInputText
                [(ngModel)]="projectDescriptor.enUsLabel">
            <label for="enUsLabel">Default Label (in English)</label>
        </span>
    </div>
    <div class="col-12 pt-5 flex-grow-1">
        <span class="p-float-label">
            <input
                class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                type="text" id="enUsHelp" pInputText
                [(ngModel)]="projectDescriptor.enUsHelp">
            <label for="enUsHelp">Default Help Text (in English)</label>
        </span>
    </div>
</div>
