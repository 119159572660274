import Quill from "quill";
import {EditorComponent} from "../editor/editor.component";
import {RNGQuillToolbarSplitButton, SplitButtonItem} from "../../api/quill/RNGQuillToolbarSplitButton";
import {SectionBlot} from "./SectionBlot";
import {TableOfContentsBlot} from "./TableOfContentsBlot";
import {Section} from "../../service/document.service";
import {LogProvider} from "../../service/error.service";

export class RNGSectionModule {
    private static quill: any;
    private options: any;

    private editorComponent: EditorComponent;
    private splitButton: RNGQuillToolbarSplitButton;
    private splitButtonItems: SplitButtonItem[];

    private readonly LOG = LogProvider.getLogger('RNGSectionModule');

    constructor(quillOb, options) {
        RNGSectionModule.quill = quillOb;
        this.options = options;

        Quill.register(SectionBlot, true);
        Quill.register(TableOfContentsBlot, true);

        this.editorComponent = this.options.editorComponent;
        this.editorComponent.setReferenceToSectionModule(this);
    }

    onAngularInit() {
        //OK - this isn't really Angular at this level - it has to be specifically called by the editor component to initialize it once it has set everything up
            this.LOG.debug('onAngularInit: readOnly? - ', this.editorComponent.isReadOnly);
            this.LOG.debug('onAngularInit: readOnly? - ', this.editorComponent.toolbarContainer);
            if (!this.editorComponent.isReadOnly) {
                const projectSchema = this.editorComponent.getProjectSchema();

                let toolbar = RNGSectionModule.quill.getModule('toolbar');
                if (toolbar) {
                    this.splitButtonItems = [];
                    let selectedItem: SplitButtonItem = null;
                    //We can only allow them to split the document - creating a new one at the current level (if the current level is > the project level) or create on at the current level + 1
                    let level = 0;
                    let initialSelectedLevel: SplitButtonItem = null;
                    for (const sl of projectSchema.sectionLayout) {
                        if (level !== 0) { //Top level isn't a split that can happen - or there wouldn't be a container for it. . .

                            const item: SplitButtonItem = {
                                label: sl.sectionName,
                                dropDownLabel: sl.toolbarDropDownLabel,
                                iconHTML: sl.toolbarIconHTML,
                                value: {
                                    level: level,
                                    sectionName: sl.sectionName,
                                    latexCode: sl.latexCode,
                                    tocIconHTML: sl.tocIconHTML
                                },
                                enabled: true
                            };
                            this.splitButtonItems.push(item);
                            if (level === projectSchema.projectLevel+1) {
                                initialSelectedLevel = item;
                            }
                        }
                        level = level + 1;
                    }
                    this.splitButton = new RNGQuillToolbarSplitButton('rng-section-splitbutton', initialSelectedLevel, this.splitButtonItems, this.options.divideSectionClick, this.addSection);

                    this.splitButton.attach(RNGSectionModule.quill);
                } else {
                    this.LOG.error('Error: WPSQLSectionModule needs toolbar');
                }
            }
    }

    addSection(section: Section) {

        if (!section) {
            return; // cannot work without the actual section data
        }



        //TODO: Split the file at the current cursor and place the defined Section at the top of the new delta.

        this.LOG.debug("CALLBACK FOR DIVIDE CALLED WITH VALUE:", section);
        //RNGSectionModule.quill.format('section', JSON.stringify(section), 'api');

    }

}
