import {EmbedBlot, Root} from "parchment";

export class SectionBlot extends EmbedBlot {

    static override className = 'ql-sectionblot';
    static override blotName = 'section';
    static override tagName = 'span';

    public sectionMarker: SectionMarker;

    constructor(scroll: Root, domNode) {
        super(scroll, domNode);

        this.sectionMarker = JSON.parse(domNode.getAttribute('section-data'));
    }

    static buildSection(value: string, node) {
        const section = JSON.parse(value);

        node.setAttribute('section-data', JSON.stringify(section));
        node.id = 'RNGSECTION-'+section.uuid;
        node.classList.add('rng-section');
        node.classList.add('no-reveal-codes');

    }

    static override create(value) {
        let node = super.create(value);

        SectionBlot.buildSection(JSON.stringify(value), node);
        return node;
    }

    static override value(node) {
        console.log('NODE: ', node);
        console.log('NODE DATA: ', node.getAttribute('section-data'));
        return JSON.parse(node.getAttribute('section-data'));
    }

}

export class SectionMarker {
    uuid: string;
}

