<p-fieldset [toggleable]="!editable" *ngIf="!reorder" class="pb-2">
    <ng-template pTemplate="header">
        <div class="flex align-items-center gap-2 px-2">
            <span class="font-bold"><i *ngIf="editable && !editing" class="pi pi-ellipsis-v" (click)="menu.toggle($event);"></i>&nbsp;{{category.variants.items.length > 0?getVariant(category, use).enUsLabel:'Please create a variant'}}&nbsp;<i *ngIf="category.variants.items.length > 0?getVariant(category, use).enUsHelp:null" class="pi pi-question-circle" [pTooltip]="category.variants.items.length > 0?getVariant(category, use).enUsHelp:'No Variants'"></i></span>
            <p-menu #menu [popup]="true" [model]="editMenuItems" appendTo="body"></p-menu>
        </div>
    </ng-template>
    <div *ngIf="editing">
        <project-descriptor-category-editor [category]="category"></project-descriptor-category-editor>
        <p-splitButton label="Save" appendTo="body" icon="pi pi-save" (onClick)="updateCategory(category)" [model]="saveMenuItems"></p-splitButton>
    </div>
    <div *ngIf="!editing" class="grid grid-nogutter">
        <!-- div class="flex flex-wrap p-1 align-items-center gap-3" -->
        <div class="col-12" [ngClass]="count > 10 ? 'md:col-6':''" *ngFor="let pd of category.descriptors.items; count as count;">
            <project-descriptor [namespace]="namespace" [editable]="editable" [descriptorCategory]="category" [variant]="getVariant(category, use)" [projectDescriptor]="pd"></project-descriptor>
        </div>
    </div>
</p-fieldset>
<div *ngIf="reorder">
    <button pButton type="button" icon="pi pi-sort-alt-slash" class="p-button-rounded p-button-text p-button-plain" pTooltip="End reorder and save" (click)="saveAfterReorder()"></button>
    <p-orderList [value]="category.descriptors.items" [listStyle]="{ height: '25rem' }" [dragdrop]="true">
        <ng-template let-descriptor pTemplate="item">
            <h5>{{descriptor.enUsLabel}}</h5>
        </ng-template>
    </p-orderList>
</div>
