/**
 * Result object returned by UpdateQueryBuilder execution.
 */
export class QueryResult {
  constructor() {
    /**
     * Rows
     */
    this.records = [];
  }
}

