import {
  Column,
  Entity,
  Index,
  JoinColumn,
  OneToMany,
  OneToOne,
  PrimaryGeneratedColumn,
} from "typeorm";
import { PlatformUser } from "./PlatformUser";
import { BetaReaderProfileSelection } from "./BetaReaderProfileSelection";
import { BetaReaderProject } from "./BetaReaderProject";

@Index("beta_reader_profile_pkey", ["id"], { unique: true })
@Index("beta_reader_profile_unique_idx", ["platformUserId"], { unique: true })
@Entity("beta_reader_profile")
export class BetaReaderProfile {
  @PrimaryGeneratedColumn({ type: "bigint", name: "id" })
  id!: string;

  @Column("bigint", { name: "platform_user_id" })
  platformUserId!: string;

  @Column("text", { name: "bio_sketch", nullable: true })
  bioSketch!: string | null;

  @OneToOne(
    () => PlatformUser,
    (platformUser) => platformUser.betaReaderProfile,
    { lazy: true }
  )
  @JoinColumn([{ name: "platform_user_id", referencedColumnName: "id" }])
  platformUser!: Promise<PlatformUser>;

  @OneToMany(
    () => BetaReaderProfileSelection,
    (betaReaderProfileSelection) =>
      betaReaderProfileSelection.betaReaderProfile,
    { lazy: true }
  )
  betaReaderProfileSelections!: Promise<BetaReaderProfileSelection[]>;

  @OneToMany(
    () => BetaReaderProject,
    (betaReaderProject) => betaReaderProject.betaReaderProfile,
    { lazy: true }
  )
  betaReaderProjects!: Promise<BetaReaderProject[]>;
}
