import { AbstractSqliteDriver } from "../sqlite-abstract/AbstractSqliteDriver";
import { CordovaQueryRunner } from "./CordovaQueryRunner";
import { DriverPackageNotInstalledError } from "../../error/DriverPackageNotInstalledError";
export class CordovaDriver extends AbstractSqliteDriver {
  // -------------------------------------------------------------------------
  // Constructor
  // -------------------------------------------------------------------------
  constructor(connection) {
    super(connection);
    this.transactionSupport = "none";
    // this.connection = connection;
    // this.options = connection.options as CordovaConnectionOptions;
    this.database = this.options.database;
    // load sqlite package
    this.loadDependencies();
  }
  // -------------------------------------------------------------------------
  // Public Methods
  // -------------------------------------------------------------------------
  /**
   * Closes connection with database.
   */
  async disconnect() {
    this.queryRunner = undefined;
    return new Promise((ok, fail) => {
      this.databaseConnection.close(ok, fail);
    });
  }
  /**
   * Creates a query runner used to execute database queries.
   */
  createQueryRunner(mode) {
    if (!this.queryRunner) this.queryRunner = new CordovaQueryRunner(this);
    return this.queryRunner;
  }
  // -------------------------------------------------------------------------
  // Protected Methods
  // -------------------------------------------------------------------------
  /**
   * Creates connection with the database.
   */
  async createDatabaseConnection() {
    const options = Object.assign({}, {
      name: this.options.database,
      location: this.options.location
    }, this.options.extra || {});
    const connection = await new Promise((resolve, fail) => {
      this.sqlite.openDatabase(options, db => resolve(db), err => fail(err));
    });
    await new Promise((ok, fail) => {
      // we need to enable foreign keys in sqlite to make sure all foreign key related features
      // working properly. this also makes onDelete to work with sqlite.
      connection.executeSql(`PRAGMA foreign_keys = ON`, [], () => ok(), err => fail(err));
    });
    return connection;
  }
  /**
   * If driver dependency is not given explicitly, then try to load it via "require".
   */
  loadDependencies() {
    try {
      const sqlite = this.options.driver || window.sqlitePlugin;
      this.sqlite = sqlite;
    } catch (e) {
      throw new DriverPackageNotInstalledError("Cordova-SQLite", "cordova-sqlite-storage");
    }
  }
}

