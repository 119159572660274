export type ProjectSchema = {
    sectionLayout: ProjectSchemaSectionLayout[],
    projectLevel: number
};

export type ProjectSchemaSectionLayout = {
    level: number,
    sectionName: string,
    toolbarSelectedName: string,
    toolbarDropDownLabel: string,
    toolbarIconHTML: string,
    tocIconHTML: string,
    latexCode: string,
    requiresName: boolean,
    hasContent: boolean,  // Some types - like series - won't have actual content. Those just exist in the TOC, not in section storage.
};

export type ProjectSchemaDisplay = {
    displayName: string,
    schema: ProjectSchema
}

export class ProjectSchemas {

    public static readonly CURRENT_VERSION: number = 1.0;

    public static readonly FICTION_SERIES: ProjectSchemaDisplay = {
        displayName: 'Fiction Book and/or Series',
        schema: {
            sectionLayout: [ //TODO: This can be overridden by the project later - once we have added the ability to select different types of projects
                {
                    level: 0,
                    sectionName: 'Series',
                    toolbarSelectedName: 'Split Series',
                    toolbarDropDownLabel: 'Split this document into separate <strong>series</strong> at the cursor when I press the <i class="scrvi scrvi-books"></i> button',
                    toolbarIconHTML: '<i class="scrvi scrvi-books"></i>',
                    tocIconHTML: '<i class="scrvi scrvi-books"></i>',
                    latexCode: 'SERIES',
                    requiresName: true,
                    hasContent: false,
                },
                {
                    level: 1,
                    sectionName: 'Book',
                    toolbarSelectedName: 'Split Book',
                    toolbarDropDownLabel: 'Split this document into separate <strong>books</strong> at the cursor when I press the <i class="scrvi scrvi-book-add"></i> button',
                    toolbarIconHTML: '<i class="scrvi scrvi-book-add"></i>',
                    tocIconHTML: '<i class="scrvi scrvi-book-add"></i>',
                    latexCode: 'BOOK',
                    requiresName: true,
                    hasContent: true,
                },
                {
                    level: 2,
                    sectionName: 'Section/Act',
                    toolbarSelectedName: 'New Section/Act',
                    toolbarDropDownLabel: 'Start a new <strong>section</strong> or <strong>act</strong> at the cursor when I press the <i class="pscrvi scrvi-section"></i> button',
                    toolbarIconHTML: '<i class="scrvi scrvi-section"></i>',
                    tocIconHTML: '<i class="scrvi scrvi-section"></i>',
                    latexCode: 'SECTION',
                    requiresName: true,
                    hasContent: true,
                },
                {
                    level: 3,
                    sectionName: 'Chapter',
                    toolbarSelectedName: 'New Chapter',
                    toolbarDropDownLabel: 'Start a new <strong>chapter</strong> at the cursor when I press the <i class="scrvi scrvi-chapter-add"></i> button',
                    toolbarIconHTML: '<i class="scrvi scrvi-chapter-add"></i>',
                    tocIconHTML: '<i class="scrvi scrvi-chapter-add"></i>',
                    latexCode: 'CHAPTER',
                    requiresName: true,
                    hasContent: true,
                },
                {
                    level: 4,
                    sectionName: 'Scene',
                    toolbarSelectedName: 'New Scene',
                    toolbarDropDownLabel: 'Start a new <strong>scene</strong> at the cursor when I press the <i class="pi pi-hashtag"></i> button',
                    toolbarIconHTML: '<i class="pi pi-hashtag"></i>',
                    tocIconHTML: '<i class="pi pi-hashtag"></i>',
                    latexCode: 'SCENE',
                    requiresName: false,
                    hasContent: true,
                }
            ],
            projectLevel: 1,  //Array index of what type this project actually "is" //TODO: This can be overridden by the project later - once we have added the ability to select different types of projects
        }
    }

    static readonly DEFAULT_PROJECT_SCHEMA: ProjectSchema = this.FICTION_SERIES.schema;
}
