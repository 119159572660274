import { PostgresDriver } from "../postgres/PostgresDriver";
import { RNGRemotePostgresQueryRunner } from "./RNGRemotePostgresQueryRunner";
class PostgresWrapper extends PostgresDriver {}
export class RNGRemotePostgresDriver extends PostgresWrapper {
  // -------------------------------------------------------------------------
  // Constructor
  // -------------------------------------------------------------------------
  constructor(connection) {
    super();

    /**
     * Represent transaction support by this driver
     */
    this.transactionSupport = "nested";
    this.connection = connection;
    this.options = connection.options;
    this.isReplicated = false;
    // load data-api package
    //this.loadDependencies();

    //console.log('CONSTRUCTOR for RNGRemotePostgres - meta: ', );

    this.client = new RNGRemotePostgresQueryRunner(this, this.options, (query, parameters) => this.connection.logger.logQuery(query, parameters), this.options.serviceConfigOptions, this.options.formatOptions);
    //console.log('RNGRemotePostgres CLIENT: ', this.client);
  }
  // -------------------------------------------------------------------------
  // Public Implemented Methods
  // -------------------------------------------------------------------------
  /**
   * Performs connection to the database.
   * Based on pooling options, it can either create connection immediately,
   * either create a pool and create connection when needed.
   */
  async connect() {}
  /**
   * Closes connection with database.
   */
  async disconnect() {}
  /**
   * Creates a query runner used to execute database queries.
   */
  createQueryRunner(mode) {
    return this.client;
    //new RNGRemotePostgresQueryRunner(this, new this.DataApiDriver(this.options.region, this.options.secretArn, this.options.resourceArn, this.options.database, (query, parameters) => this.connection.logger.logQuery(query, parameters), this.options.serviceConfigOptions, this.options.formatOptions), mode);
  }
  /**
   * Prepares given value to a value to be persisted, based on its column type and metadata.
   */
  /*preparePersistentValue(value, columnMetadata) {
      if (this.options.formatOptions &&
          this.options.formatOptions.castParameters === false) {
          return super.preparePersistentValue(value, columnMetadata);
      }
      if (columnMetadata.transformer)
          value = ApplyValueTransformers.transformTo(columnMetadata.transformer, value);
      return this.client.preparePersistentValue(value, columnMetadata);
  }*/
  /**
   * Prepares given value to a value to be persisted, based on its column type and metadata.
   */
  /*prepareHydratedValue(value, columnMetadata) {
      if (this.options.formatOptions &&
          this.options.formatOptions.castParameters === false) {
          return super.prepareHydratedValue(value, columnMetadata);
      }
      if (columnMetadata.transformer)
          value = ApplyValueTransformers.transformFrom(columnMetadata.transformer, value);
      return this.client.prepareHydratedValue(value, columnMetadata);
  }*/
  // -------------------------------------------------------------------------
  // Protected Methods
  // -------------------------------------------------------------------------
  /**
   * If driver dependency is not given explicitly, then try to load it via "require".
   */
  loadDependencies() {
    //const driver = this.options.driver ||
    //    PlatformTools.load("typeorm-rng-remote-postgres-driver");
    //const { pg } = driver;
    //this.DataApiDriver = pg;
  }
  /**
   * Executes given query.
   */
  executeQuery(connection, query) {
    return this.connection.query(query);
  }
  /**
   * Makes any action after connection (e.g. create extensions in Postgres driver).
   */
  async afterConnect() {
    //TODO: Relay this to the remote
    /*const extensionsMetadata = await this.checkMetadataForExtensions();
    if (extensionsMetadata.hasExtensions) {
        await this.enableExtensions(extensionsMetadata, this.connection);
    } */
    return Promise.resolve();
  }
}

