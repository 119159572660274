export const environment = {
    production: false,
    lambdaApi: 'https://la361oi2dk.execute-api.us-east-1.amazonaws.com',
    dbHost: "runeandgear.c1saqvbg7vht.us-east-1.rds.amazonaws.com",
    dbPort: 5432,
    dbDatabase: "runeandgear",
    dbSynchronize: false,
    dbLogging: true,
    dbCache: {
        options: {
            socket: {
                host: "scrivendium-cache-1-0001-001.scrivendium-cache-1.pewo8t.use1.cache.amazonaws.com",
                port: 6379
            }
        }
    },
    RNGRemoteLambda: "https://63lztxo410.execute-api.us-east-1.amazonaws.com/_/rng_remote_staging"

};
