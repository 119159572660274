<p-dropdown [options]="icons" [(ngModel)]="value" [filter]="true" [showClear]="true" [virtualScroll]="true" [virtualScrollItemSize]="20" placeholder="Select an Icon" appendTo="body" (blur)="onBlur()">
    <ng-template pTemplate="selectedItem">
        <div class="flex align-items-center gap-2" *ngIf="value">
            <i [class]="['pi', value]"></i>
            <div>{{ value }}</div>
        </div>
    </ng-template>
    <ng-template let-icon pTemplate="item">
        <div class="flex align-items-center gap-2">
            <i [class]="['pi', icon]"></i>
            <div>{{ icon }}</div>
        </div>
    </ng-template>
</p-dropdown>
