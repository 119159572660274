import {Component} from '@angular/core';
import {NgStyle} from "@angular/common";


@Component({
    selector: 'user-word-count',
    standalone: true,
    imports: [
        NgStyle
    ],
    templateUrl: './user-word-count.component.html'
})

export class UserWordCountComponent {
    wordCount: number = -1;

    constructor() {
    }
}
