import {
  Column,
  Entity,
  Index,
  OneToOne,
  PrimaryGeneratedColumn,
} from "typeorm";
import { BetaReaderProfile } from "./BetaReaderProfile";
import { WriterProfile } from "./WriterProfile";

@Index("platform_user_cognito_acct", ["cognitoAcct"], { unique: true })
@Index("platform_user_pkey", ["id"], { unique: true })
@Index("platform_user_screen_name", ["screenName"], { unique: true })
@Index("platform_user_uuid", ["uuid"], { unique: true })
@Entity("platform_user")
export class PlatformUser {
  @PrimaryGeneratedColumn({ type: "bigint", name: "id" })
  id!: string;

  @Column("text", { name: "email" })
  email!: string;

  @Column("text", { name: "cognito_acct" })
  cognitoAcct!: string;

  @Column("text", { name: "family_name", nullable: true })
  familyName!: string | null;

  @Column("text", { name: "given_name", nullable: true })
  givenName!: string | null;

  @Column("text", { name: "screen_name", nullable: true })
  screenName!: string | null;

  @Column("text", { name: "timezone", nullable: true })
  timezone!: string | null;

  @Column("text", { name: "roles", nullable: true, array: true })
  roles!: string[] | null;

  @Column("uuid", { name: "uuid", default: () => "gen_random_uuid()" })
  uuid!: string;

  @Column("text", { name: "pad_author_id", nullable: true })
  padAuthorId!: string | null;

  @OneToOne(
    () => BetaReaderProfile,
    (betaReaderProfile) => betaReaderProfile.platformUser,
    { lazy: true }
  )
  betaReaderProfile!: Promise<BetaReaderProfile>;

  @OneToOne(
    () => WriterProfile,
    (writerProfile) => writerProfile.platformUser,
    { lazy: true }
  )
  writerProfile!: Promise<WriterProfile>;
}
