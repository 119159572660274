import {
    Column,
    Entity,
    Index,
    JoinColumn,
    ManyToOne,
    OneToMany,
    PrimaryGeneratedColumn, VersionColumn,
} from "typeorm";
import { BetaReaderProject } from "./BetaReaderProject";
import { DocumentSection } from "./DocumentSection";
import { WriterProfile } from "./WriterProfile";
import { ProjectProfileSelection } from "./ProjectProfileSelection";
import {TableOfContents} from "../../document.service";
import {Delta} from "quill/core";

@Index("project_pkey", ["id"], { unique: true })
@Index("project_uuid", ["uuid"], { unique: true })
@Entity("project")
export class Project {
  @PrimaryGeneratedColumn({ type: "bigint", name: "id" })
  id!: string;

  @Column("text", { name: "summary", nullable: true })
  summary!: string | null;

  @Column("boolean", {
    name: "looking_for_beta_readers",
    default: () => "false",
  })
  lookingForBetaReaders!: boolean;

  @Column("text", { name: "title", nullable: true })
  title!: string | null;

  @Column("text", { name: "pen_name_given_name", nullable: true })
  penNameGivenName!: string | null;

  @Column("text", { name: "pen_name_surname", nullable: true })
  penNameSurname!: string | null;

  @Column("boolean", { name: "uses_pen_name", default: () => "false" })
  usesPenName!: boolean;

  @Column("uuid", { name: "uuid" })
  uuid!: string;

  @Column("text", { name: "pad_group_id", nullable: true })
  padGroupId!: string | null;

  @Column("boolean", { name: "pad_initialized", default: () => "false" })
  padInitialized!: boolean;

  @Column("text", { name: "pad_main_rw_pad_id", nullable: true })
  padMainRwPadId!: string | null;

  @Column("text", { name: "pad_main_ro_pad_id", nullable: true })
  padMainRoPadId!: string | null;

  @Column("text", { name: "current_delta_content", nullable: true })
  currentDeltaContent!: string | null;

  @Column("jsonb", { name: "current_table_of_contents", nullable: true })
  currentTableOfContents!: TableOfContents | null;

  @VersionColumn({ type: "bigint", name: "document_version", default: 0 })
  documentVersion!: number

  @OneToMany(
    () => BetaReaderProject,
    (betaReaderProject) => betaReaderProject.project,
    { lazy: true }
  )
  betaReaderProjects!: Promise<BetaReaderProject[]>;

  @OneToMany(
    () => DocumentSection,
    (documentSection) => documentSection.project,
    { lazy: true }
  )
  documentSections!: Promise<DocumentSection[]>;

  @ManyToOne(() => WriterProfile, (writerProfile) => writerProfile.projects, {
    lazy: true,
  })
  @JoinColumn([{ name: "writer_profile_id", referencedColumnName: "id" }])
  writerProfile!: Promise<WriterProfile>;

  @OneToMany(
    () => ProjectProfileSelection,
    (projectProfileSelection) => projectProfileSelection.project,
    { lazy: true }
  )
  projectProfileSelections!: Promise<ProjectProfileSelection[]>;
}
