import {Component, NgZone, OnDestroy, OnInit, signal, WritableSignal} from '@angular/core';
import {Project} from "../../service/db/entities/Project";
import {NgClass, NgForOf, NgIf} from "@angular/common";
import {ProjectProfileComponent} from "../writer-dashboard/project-profile/project-profile.component";
import {
    WriterResourceMatchesComponent
} from "../writer-dashboard/writer-resource-matches/writer-resource-matches.component";
import {ButtonModule} from "primeng/button";
import {DataService, RoleProject} from "../../service/data.service";
import {DialogModule} from "primeng/dialog";
import {BadgeModule} from "primeng/badge";
import {Observable, Subscription} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {PaginatorModule} from "primeng/paginator";
import {v4 as uuid} from "uuid";
import Quill from "quill";
import {Comment, Reply, RNGComments} from "../comment/RNG_Comments";
import {QuillEditorComponent} from "ngx-quill";
import {InputSwitchModule} from "primeng/inputswitch";
import {CommentComponent} from "../comment/comment.component";
import {PlatformRole} from "../../api/enums";
import {RNGQLAutosave} from "./RNG_QL_Autosave";
import {ErrorService, LogProvider} from "../../service/error.service";
import {RNGSectionModule} from "../contents-management/RNG_Section_Module";
import {Delta} from 'quill/core';
import {DocumentService, Section, SectionLink, TableOfContents} from "../../service/document.service";
import {ProjectSchemas, ProjectSchemaSectionLayout} from "../../api/project_schemas";
import {MessageService} from "primeng/api";
import {ProgressService} from "../../service/progress.service";

@Component({
    selector: 'editor',
    standalone: true,
    templateUrl: './editor.component.html',
    imports: [
        NgIf,
        ButtonModule,
        DialogModule,
        BadgeModule,
        PaginatorModule,
        QuillEditorComponent,
        InputSwitchModule,
        CommentComponent
    ],
    styleUrl: './editor.component.scss'
})
export class EditorComponent implements OnInit, OnDestroy {

    private static readonly LOG = LogProvider.getLogger('EditorComponent');

    private static quill: any;
    private static progressService: ProgressService;
    private referenceToAutosaveModule: RNGQLAutosave;
    private referenceToCommentModule: RNGComments;
    private referenceToSectionModule: RNGSectionModule;

    protected uuid: string;

    protected newReply: Reply;

    private roToolbarContainer = [
        ['force-save'],
        ['comments-toggle', 'comments-add']
    ];

    private rwToolbarContainer = [
        ['force-save'],
        ['bold', 'italic', 'underline', 'strike'],
        [{'indent': '-1'}, {'indent': '+1'}],
        [{'list': 'ordered'}, {'list': 'bullet'}, {'list': 'check'}],
        [{'align': []}],
        [{'font': []}, {size: ['small', false, 'large', 'huge']}],
        ['comments-toggle', 'comments-add'],
        ['clean']
    ];

    public isReadOnly: boolean = null;
    public toolbarContainer: any = null;

    protected modules: any = {
        toolbar: {
            container: null,
            handlers: {
                'comments-toggle': () => {
                    EditorComponent.LOG.debug('toggle was clicked')
                },
                'comments-add': () => {
                    EditorComponent.LOG.debug('add was clicked')
                },
                'force-save': () => {
                    EditorComponent.LOG.debug('force save was clicked')
                }
            }
        },
        comments: {
            enabled: true,
            commentAddClick: this.commentAddClick.bind(this), // get called when `ADD COMMENT` btn on options bar is clicked
            commentToggleUpdate: this.commentsToggle.bind(this), // get called when you click `COMMENTS` btn on options bar for you to do additional things beside color on/off. Color on/off is already done before the callback is called.
            editorComponent: this
        },
        autosave: {
            onForceSaveClickCallback: this.onForceSaveClickCallback.bind(this),
            onDocumentDirtyCallback: this.onDocumentDirtyCallback.bind(this),
            updateFullExternalDocument: this.updateFullExternalDocument.bind(this),
            onDocumentSaved: this.onDocumentSaved.bind(this),
            editorComponent: this,
            timeout: 5000
        },
        sections: {
            divideSectionClick: this.onDivideSection.bind(this),
            editorComponent: this
        }
    };


    static userColors: { background: string, foreground: string }[] = [
        {
            background: '#ffcccc',
            foreground: '#000000'
        },
        {
            background: '#ccffcc',
            foreground: '#000000'
        },
        {
            background: '#ccccff',
            foreground: '#000000'
        },
        {
            background: '#ffffcc',
            foreground: '#000000'
        },
        {
            background: '#ccffff',
            foreground: '#000000'
        },
        {
            background: '#ffccff',
            foreground: '#000000'
        },
        {
            background: '#ffe0cc',
            foreground: '#000000'
        },
        {
            background: '#e0ffe0',
            foreground: '#000000'
        },
        {
            background: '#e0ccff',
            foreground: '#000000'
        },
        {
            background: '#ffcce0',
            foreground: '#000000'
        },
        {
            background: '#e0cce0',
            foreground: '#000000'
        },
    ];
    protected readyToInitializeQuill: WritableSignal<boolean> = signal(false);
    private static disableAutosave: boolean = false;

    get userColors() {
        return EditorComponent.userColors;
    }

    private subscriptions: Subscription[] = [];

    //Need to know what role I have on this specific project
    protected showComments = signal(false);
    protected showMultiple: boolean = false;

    protected static showAddCommentDialog = signal(false);
    get showAddCommentDialog() {
        return EditorComponent.showAddCommentDialog;
    }

    //set showAddCommentDialog(sacd: boolean) { EditorComponent.showAddCommentDialog = sacd; }

    protected static selectedComment = signal<Comment>(null);
    get selectedComment() {
        return EditorComponent.selectedComment;
    }

    private static roleProject: RoleProject;
    get roleProject() {
        return EditorComponent.roleProject;
    }

    public static platformUser = null;

    //CURRENT TOC VERSION
    private static readonly VERSION = 1.0;

    constructor(private route: ActivatedRoute,
                private errorService: ErrorService,
                protected dataService: DataService,
                private documentService: DocumentService,
                private zone: NgZone,
                private messageService: MessageService,
                private progressService: ProgressService ) {

        EditorComponent.progressService = progressService;

        window['angularComponentRef'] = {
            zone: this.zone,
            showComment: (comment, offset) => {
                this.displayComment(comment, offset)
            },
            component: this
        }

        const icons = Quill.import('ui/icons');

        Quill.debug('error');

        if (!Quill.import('modules/comments')) {
            Quill.register('modules/comments', RNGComments);
            icons['comments-toggle'] = '<i id="commentsToggleButton" class="pi pi-eye" aria-hidden="true"></i>';
            icons['comments-add'] = '<i id="commentsAdButton" class="pi pi-comment" aria-hidden="true"></i>';
        }
        if (!Quill.import('modules/autosave')) {
            Quill.register('modules/autosave', RNGQLAutosave);
            icons['force-save'] = '<i id="forceSaveButton" class="pi pi-save" aria-hidden="true"></i>';
        }

        if (!Quill.import('modules/sections')) {
            Quill.register('modules/sections', RNGSectionModule);
        }

        Quill.debug('info');

    }

    async onEditorCreated(quill) {
        EditorComponent.quill = quill;

        const proj = this.documentService.selectedProject();
        if (!proj) {
            return;
        }
        let toc: TableOfContents = this.documentService.tableOfContents();

        EditorComponent.LOG.debug('Loaded Project and TOC on EditorCreate: ', proj, toc);


        if (!toc) {
            //No TOC - time to add one
            const initialSection: Section = {
                sectionVersion: 0,
                uuid: uuid(),
                level: ProjectSchemas.DEFAULT_PROJECT_SCHEMA.projectLevel,
                name: proj.title,
                metadata: []
            };

            toc = {
                documentTimestamp: new Date(),
                documentUpdater: this.dataService.user().uuid,
                documentVersion: 0,
                projectSchema: ProjectSchemas.DEFAULT_PROJECT_SCHEMA,
                topLevelSectionLink: {
                    section: initialSection,
                    childLinks: []
                },
                schemaVersion: EditorComponent.VERSION
            };

            await this.documentService.createDocumentSectionContent(initialSection, JSON.parse(this.documentService.selectedProject().currentDeltaContent));

            EditorComponent.LOG.debug('Setting toc: ', toc);

            await this.documentService.saveTableOfContents(toc);
        }

        EditorComponent.LOG.debug('Loaded TOC: ',toc);

        let delta: Delta = (await this.documentService.getDocumentSectionContent(toc.topLevelSectionLink.section))?.delta;

        if ( !delta ) {
            delta = JSON.parse(proj.currentDeltaContent);
            EditorComponent.LOG.debug('Upgrading delta: ',delta);
            this.messageService.add({
                severity: "info",
                summary: "Upgrading",
                detail: "Upgrading document to latest version."
            });
            await this.documentService.createDocumentSectionContent(toc.topLevelSectionLink.section, delta);
        }

        EditorComponent.LOG.debug('delta to load: ', delta);
        EditorComponent.LOG.debug('delta on load: ', await EditorComponent.displayContent(delta));
        EditorComponent.quill.history.clear(); //Don't allow undo that removes all content.

        //VERSIONING CODE
        if (!toc.schemaVersion) {
            toc.schemaVersion = 0.1;
        }

        if (EditorComponent.VERSION !== toc.schemaVersion) {
            //Data upgrade needed - hardcode each step here.
            if (toc.schemaVersion === 0.1) {
                //projectSchema missing
                toc.projectSchema = ProjectSchemas.DEFAULT_PROJECT_SCHEMA; //This will always use the hardcoded "default", since this means that this predated setting it on the project itself
                toc.schemaVersion = 1.0; //Upgrade to 1.0 complete
            }

            //Upgrades complete - save it back to the model
            EditorComponent.LOG.info('Upgraded TOC: ',toc);
            await this.documentService.saveTableOfContents(toc);
        }

        this.documentService.currentSectionContent.set(await this.documentService.getDocumentSectionContent(this.documentService.tableOfContents().topLevelSectionLink.section));

        this.referenceToSectionModule.onAngularInit(); //Everything is set up on this side, so now the section can finish its setup.

        EditorComponent.quill.on(Quill.events.TEXT_CHANGE, this.update.bind(this));

        EditorComponent.LOG.debug('Leaving setup: ', toc, EditorComponent.quill.getContents());
    }

    update() { //Called when the content of the editor is changed by a user

        if (! EditorComponent.disableAutosave) {
            this.referenceToAutosaveModule.flagDocumentDirty();
        }
        /* const length = this.calculate();
        let label = this.options.unit;
        if (length !== 1) {
            label += 's';
        }
        this.container.innerText = `${length} ${label}`; */
        //EditorComponent.LOG.debug('Update Called on Comment Plugin');
        //EditorComponent.LOG.debug(JSON.stringify(WPSQLComments.quill.getContents()));
    }

    async onDivideSection(callback, value: ProjectSchemaSectionLayout) {
        EditorComponent.LOG.debug('Divide Section Called! Incoming Value: ', value);
        EditorComponent.LOG.debug('Initial TOC: ', this.documentService.tableOfContents());

        const splitIndex = EditorComponent.quill.getSelection(true).index;
        EditorComponent.LOG.debug('SplitIndex: ', splitIndex);

        if (!splitIndex) {
            return; // do nth, cuz nothing is selected
        }

        if (value.level <= 0) {
            //NOPE - how did you do this anyway?
            this.errorService.handleError({
                detail: "Divide section called on or above(?!?) top level - disallowed.",
                severity: "SEVERE",
                stack: undefined,
                summary: "Divide section called on or above(?!?) top level - disallowed. Please report this to support."
            });
            return;
        }

        const currentSection = this.documentService.currentSectionContent().section;

        let toc = this.documentService.tableOfContents();
        const schema = toc.projectSchema;

        let newSection: Section;

        EditorComponent.LOG.debug('Levels for comparison: ', currentSection.level, value.level);


        //Now we know we aren't the top level.
        if (currentSection.level === value.level) {
            //This is a split that creates a sibling. In this case, adjust the parent level.


            let parentSectionLink = this.documentService.getParentSectionLinkFromTOC(currentSection);
            if (!parentSectionLink) {
                EditorComponent.LOG.debug('CREATING NEW PARENT LEVEL');
                await this.createNewParentLevel();
                toc = this.documentService.tableOfContents();
                parentSectionLink = this.documentService.getParentSectionLinkFromTOC(currentSection);
            }

            //First, do the TOC update
            newSection = {
                sectionVersion: 0,
                uuid: uuid(),
                level: value.level,
                name: schema.sectionLayout[value.level].sectionName + ' ' + (parentSectionLink.sectionLink.childLinks.length + 1),
                metadata: []
            };

            parentSectionLink.sectionLink.childLinks.push({
                section: newSection,
                childLinks: []
            });
            await this.documentService.saveTableOfContents(toc);

        } else if (currentSection.level > value.level) {
            //New level is lower than current - so we adjust the first parent that is lower level than the requested level
            //If it is lower than the current root, then we have to adjust the root

            let parentSectionLink = this.documentService.getParentSectionLinkFromTOC(currentSection, value.level - 1);
            while (!parentSectionLink) {
                await this.createNewParentLevel();
                toc = this.documentService.tableOfContents();
                parentSectionLink = this.documentService.getParentSectionLinkFromTOC(currentSection, value.level - 1);
            }
            newSection = {
                sectionVersion: 0,
                uuid: uuid(),
                level: value.level,
                name: schema.sectionLayout[value.level].sectionName + ' ' + (parentSectionLink.sectionLink.childLinks.length + 1),
                metadata: []
            };
            const afterIdx = parentSectionLink.childIndex;
            parentSectionLink.sectionLink.childLinks.splice(afterIdx + 1, 0, {
                section: newSection,
                childLinks: []
            });
            await this.documentService.saveTableOfContents(toc);
        } else {
            //New level is greater - so we add this as a child of the current section
            EditorComponent.LOG.debug('currentSection and toc', currentSection, this.documentService.tableOfContents());


            const currentSectionLink = this.documentService.getSectionLinkFromTOC(currentSection);
            EditorComponent.LOG.debug('currentSectionLink', currentSectionLink);


            newSection = {
                sectionVersion: 0,
                uuid: uuid(),
                level: value.level,
                name: schema.sectionLayout[value.level].sectionName + ' ' + (currentSectionLink.sectionLink.childLinks.length + 1),
                metadata: []
            };

            currentSectionLink.sectionLink.childLinks.push({
                section: newSection,
                childLinks: []
            });

            EditorComponent.LOG.debug('currentSectionLink, toc after update', currentSectionLink);

            await this.documentService.saveTableOfContents(toc);
        }

        let splitDoc = await this.splitDocument(splitIndex);
        const before = this.documentService.updateDocumentSectionContent(splitDoc.beforeSplit, currentSection);
        const after = this.documentService.createDocumentSectionContent(newSection, splitDoc.afterSplit);
        await EditorComponent.displayContent(splitDoc.beforeSplit);

        callback(currentSection);
    }

    private async createNewParentLevel() {
        //Special case - no parent - we are the top level - so let's create a new top and then it works the same from there
        const toc = this.documentService.tableOfContents();
        const schema = toc.projectSchema;
        const newLevel = toc.topLevelSectionLink.section.level - 1;

        if (newLevel < 0) {
            //NOPE - how did you do this anyway?
            this.errorService.handleError({
                detail: "Create new Parent Level called above top level - disallowed.",
                severity: "SEVERE",
                stack: undefined,
                summary: "Create new Parent Level called above top level - disallowed. Please report this to support."
            });
            throw new Error('Create new Parent Level called above top level - disallowed');
        }


        let newTopSection: Section = {
            sectionVersion: 0,
            uuid: uuid(),
            level: newLevel,
            name: schema.sectionLayout[newLevel].sectionName,
            metadata: []
        };

        const oldTopLevelSectionLink = toc.topLevelSectionLink;

        const newTopLink: SectionLink = {
            childLinks: [
                oldTopLevelSectionLink
            ],
            section: newTopSection
        };
        toc.topLevelSectionLink = newTopLink;
        toc.projectSchema.projectLevel = newLevel;
        await this.documentService.saveTableOfContents(toc);
    }

    private async splitDocument(lastIndexToKeep: number): Promise<{ beforeSplit: Delta, afterSplit: Delta }> {
        const retDelta: { beforeSplit: Delta, afterSplit: Delta } = {
            beforeSplit: EditorComponent.quill.getContents(0, lastIndexToKeep + 1),
            afterSplit: EditorComponent.quill.getContents(lastIndexToKeep + 1)
        };
        return retDelta;
    }

    commentAddClick(callback, wpsQlCommentsOb) {
        //EditorComponent.LOG.debug("AddComment Triggered");
        //NOTE: "this" is not set when Quill calls this function!
        //EditorComponent.LOG.debug("WPSQLComments: ", wpsQlCommentsOb);
        let colorIdx = EditorComponent.roleProject.colorIndex;

        let comment: Comment = new Comment({
            colorIndex: colorIdx,
            iconHTML: (
                (EditorComponent.roleProject.role === PlatformRole.BETAREADER) ? '<i class="comment-icon pi pi-comment"></i>' :
                    (EditorComponent.roleProject.role === PlatformRole.WRITER) ? '<i class="comment-icon pi pi-pencil"></i>' :
                        'error' //No Role Project
            ),
            authorRole: (
                (EditorComponent.roleProject.role === PlatformRole.BETAREADER) ? 'Beta Reader' :
                    (EditorComponent.roleProject.role === PlatformRole.WRITER) ? 'Author' :
                        'error' //No Role Project
            ),
            comment: null,
            authorUUID: this.dataService.user().uuid,
            authorScreenName: this.dataService.user().screenName,
            timestamp: new Date(),
            hidden: false,
            replies: null,
            uuid: uuid(),
            userFirstViews: [],
            range: wpsQlCommentsOb.quill.getSelection(),
            callback: callback
        });
        EditorComponent.selectedComment.set(comment);
        EditorComponent.showAddCommentDialog.set(true);
    }

    commentsToggle(enabled: boolean) {
        // comments btn callback
        EditorComponent.LOG.debug("CommentsToggle Triggered");

        const el = document.getElementById("commentsToggleButton");
        el.className = "pi " + (enabled ? "pi-eye" : "pi-eye-slash");
        const css = document.getElementById("ql-comment-visible");
    }

    displayComment(comment, offset) {
        const commentData = comment.domNode.attributes['comment-data'].value;
        let commentOb = <Comment>JSON.parse(commentData);
        EditorComponent.selectedComment.set(commentOb);
        //EditorComponent.LOG.debug('Multi: ', this.showMultiple);
        let colorIdx = EditorComponent.roleProject.colorIndex;
        this.newReply = new Reply({
            colorIndex: colorIdx,
            iconHTML: (
                (EditorComponent.roleProject.role === PlatformRole.BETAREADER) ? '<i class="comment-icon pi pi-comment"></i>' :
                    (EditorComponent.roleProject.role === PlatformRole.WRITER) ? '<i class="comment-icon pi pi-pencil"></i>' :
                        'error' //No Role Project
            ),
            authorRole: (
                (EditorComponent.roleProject.role === PlatformRole.BETAREADER) ? 'Beta Reader' :
                    (EditorComponent.roleProject.role === PlatformRole.WRITER) ? 'Author' :
                        'error' //No Role Project
            ),
            comment: null,
            authorUUID: this.dataService.user().uuid,
            authorScreenName: this.dataService.user().screenName,
            timestamp: new Date(),
            hidden: false,
            userFirstViews: [],
            replies: null
        });
        this.showComments.set(true);
    }

    closeComment() {
        EditorComponent.showAddCommentDialog.set(false);
        this.showComments.set(false);

    }

    saveNewComment() {
        EditorComponent.showAddCommentDialog.set(false);
        EditorComponent.selectedComment().callback(EditorComponent.selectedComment());
    }

    onForceSaveClickCallback(autoSaveOb) {
    }

    onDocumentDirtyCallback(autoSaveOb) {
        const el = document.getElementById("forceSaveButton");
        el.style.color = "orange";
    }

    async updateFullExternalDocument(delta: any, autoSaveOb) {
        await this.documentService.updateDocumentSectionContent(delta);
    }

    onDocumentSaved(autoSaveOb) {
        const el = document.getElementById("forceSaveButton");
        el.style.color = "green";
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
        this.documentService.tableOfContents.set(null);
        this.documentService.currentSectionContent.set(null);
    }

    async ngOnInit() {
        this.subscriptions.push(this.route.params.subscribe(async params => {
            //EditorComponent.LOG.debug(params);
            this.uuid = params['project'];
            //Need this to return to the previous url
            //EditorComponent.LOG.debug('DEBUG: Initial: ', params['retUrl']);
            //EditorComponent.LOG.debug('DEBUG: decodeURI: ', decodeURI(params['retUrl']));
            //EditorComponent.LOG.debug('DEBUG: b64 decode: ', atob(decodeURI(params['retUrl'])));
            this.dataService.redirectUrl = atob(decodeURI(params['retUrl']));

            //DataService can translate writer projects and betareader projects, etc. Then get the core project
            const prj: Project = await this.documentService.getProjectForUUID(this.dataService.getProjectForUUID(this.uuid).uuid);

            await this.documentService.setSelectedProject(prj);
            EditorComponent.LOG.debug('DEBUG - ngOnInit: prj ', prj);
            //EditorComponent.LOG.debug('DEBUG: uuid ', this.uuid);
            EditorComponent.roleProject = this.dataService.getRoleBasedProjectForUUID(this.uuid);
            EditorComponent.LOG.debug('DEBUG: roleProject ', EditorComponent.roleProject);

            EditorComponent.platformUser = this.dataService.user();

            switch (EditorComponent.roleProject.role) {
                case PlatformRole.WRITER:
                    this.isReadOnly = false;
                    EditorComponent.LOG.debug('DEBUG: BEFORE SET RW ', this.modules.toolbar);
                    this.toolbarContainer = this.rwToolbarContainer;
                    EditorComponent.LOG.debug('DEBUG: SET RW ', this.modules.toolbar);
                    break;
                case PlatformRole.BETAREADER:
                    this.isReadOnly = true;
                    this.toolbarContainer = this.roToolbarContainer;
                    break;
                default:
                    this.isReadOnly = true;
                    this.toolbarContainer = this.roToolbarContainer;
                    this.errorService.handleError({
                        ancillaries: [EditorComponent.roleProject.role],
                        detail: "Unable to match Role to select ReadOnly: " + EditorComponent.roleProject.role,
                        severity: "SEVERE",
                        stack: undefined,
                        summary: "Unable to match Role to select ReadOnly"
                    });
            }
            this.modules.toolbar.container = this.toolbarContainer;
            this.readyToInitializeQuill.set(true);

            if (!prj.currentTableOfContents) {
                this.dataService.switchToBaseLayout();
            }
        }));
    }

    async closeNewReply() {
        this.showComments.set(false);
        //Update other replies
        if (!EditorComponent.selectedComment().replies) {
            EditorComponent.selectedComment().replies = [];
        }
        await this.referenceToCommentModule.updateCommentReplies(EditorComponent.selectedComment());
    }

    async saveNewReply() {
        this.showComments.set(false);
        EditorComponent.selectedComment().replies.push(this.newReply);
        await this.closeNewReply();
    }

    setReferenceToAutosaveModule(reference: RNGQLAutosave) {
        this.referenceToAutosaveModule = reference;
    }

    setReferenceToCommentModule(reference: RNGComments) {
        this.referenceToCommentModule = reference;
    }

    setReferenceToSectionModule(reference: RNGSectionModule) {
        this.referenceToSectionModule = reference;
    }

    markViewed() {
        Comment.markViewed(this.selectedComment(), this.dataService.user());
        if (this.selectedComment().replies?.length > 0) {
            Reply.markViewed(this.selectedComment().replies[this.selectedComment().replies.length - 1], this.dataService.user());
        }
    }

    /*getCurrentSection(): Section {
        return this.dataService.currentSection();
    }*/
    getProjectSchema() {
        return this.documentService.tableOfContents().projectSchema;
    }

    static async displayContent(delta: Delta): Promise<Delta> {
        //Don't mark as dirty for a load . . .
        this.progressService.blockWithMessage('Loading section');
        this.disableAutosave = true;
        const ret = this.quill.setContents(delta);
        this.disableAutosave = false;
        this.progressService.unBlock();
        return ret;


    }
}

