import { QueryRunnerAlreadyReleasedError } from "../../error/QueryRunnerAlreadyReleasedError";
import { TransactionNotStartedError } from "../../error/TransactionNotStartedError";
import { PostgresQueryRunner } from "../postgres/PostgresQueryRunner";
import { QueryResult } from "../../query-runner/QueryResult";
class PostgresQueryRunnerWrapper extends PostgresQueryRunner {
  constructor(driver, mode) {
    super(driver, mode);
  }
}
/**
 * Runs queries on a single postgres database connection.
 */
export class RNGRemotePostgresQueryRunner extends PostgresQueryRunnerWrapper {
  // -------------------------------------------------------------------------
  // Constructor
  // -------------------------------------------------------------------------
  constructor(driver, options, logger, serviceConfigOptions, formatOptions) {
    super(driver, 'master');
    this.user = options.user;
    this.remoteURL = options.remoteURL;
    this.logger = logger;
    this.serviceConfigOptions = serviceConfigOptions;
    this.formatOptions = formatOptions;
  }
  // -------------------------------------------------------------------------
  // Public Methods
  // -------------------------------------------------------------------------
  /**
   * Creates/uses database connection from the connection pool to perform further operations.
   * Returns obtained database connection.
   */
  connect() {
    if (this.databaseConnection) return Promise.resolve(this.databaseConnection);
    if (this.databaseConnectionPromise) return this.databaseConnectionPromise;
    if (this.mode === "slave" && this.driver.isReplicated) {
      this.databaseConnectionPromise = this.driver.obtainSlaveConnection().then(([connection, release]) => {
        this.driver.connectedQueryRunners.push(this);
        this.databaseConnection = connection;
        this.releaseCallback = release;
        return this.databaseConnection;
      });
    } else {
      // master
      this.databaseConnectionPromise = this.driver.obtainMasterConnection().then(([connection, release]) => {
        this.driver.connectedQueryRunners.push(this);
        this.databaseConnection = connection;
        this.releaseCallback = release;
        return this.databaseConnection;
      });
    }
    return this.databaseConnectionPromise;
  }
  release() {
    //This is a NOP, here - since we don't actually have the connection, no reason to kill it, like ever.
  }

  /**
   * Starts transaction on the current connection.
   */
  async startTransaction(isolationLevel) {
    this.isTransactionActive = true;
    try {
      await this.broadcaster.broadcast("BeforeTransactionStart");
    } catch (err) {
      this.isTransactionActive = false;
      throw err;
    }

    //TODO: Actually reserve a slot in the remote and start transaction
    /*if (this.transactionDepth === 0) {
        this.transactionDepth += 1;
        await this.client.startTransaction();
    }
    else {
        this.transactionDepth += 1;
        await this.query(`SAVEPOINT typeorm_${this.transactionDepth} - 1`);
    }*/
    await this.broadcaster.broadcast("AfterTransactionStart");
  }
  /**
   * Commits transaction.
   * Error will be thrown if transaction was not started.
   */
  async commitTransaction() {
    if (!this.isTransactionActive) throw new TransactionNotStartedError();
    await this.broadcaster.broadcast("BeforeTransactionCommit");

    //TODO: Commit reserved slot transaction and release slot
    /*
    if (this.transactionDepth > 1) {
        this.transactionDepth -= 1;
        await this.query(`RELEASE SAVEPOINT typeorm_${this.transactionDepth}`);
    }
    else {
        this.transactionDepth -= 1;
        await this.client.commitTransaction();
        this.isTransactionActive = false;
    }*/
    await this.broadcaster.broadcast("AfterTransactionCommit");
  }
  /**
   * Rollbacks transaction.
   * Error will be thrown if transaction was not started.
   */
  async rollbackTransaction() {
    if (!this.isTransactionActive) throw new TransactionNotStartedError();
    await this.broadcaster.broadcast("BeforeTransactionRollback");

    //TODO: Commit reserved slot transaction and release slot

    /*if (this.transactionDepth > 1) {
        this.transactionDepth -= 1;
        await this.query(`ROLLBACK TO SAVEPOINT typeorm_${this.transactionDepth}`);
    }
    else {
        this.transactionDepth -= 1;
        await this.client.rollbackTransaction();
        this.isTransactionActive = false;
    }*/
    await this.broadcaster.broadcast("AfterTransactionRollback");
  }
  async queryRemote(query, parameters) {
    const data = JSON.stringify({
      query: query,
      parameters: parameters
    });
    console.log('Sending to remote:', data);
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", this.user);
    myHeaders.append("Content-Length", data.length);
    return fetch(this.remoteURL, {
      method: "POST",
      body: data,
      headers: myHeaders
    });
  }

  /**
   * Executes a given SQL query.
   */
  async query(query, parameters, useStructuredResult = false) {
    if (this.isReleased) throw new QueryRunnerAlreadyReleasedError();
    const raw = await this.queryRemote(query, parameters);
    const result = new QueryResult();
    //console.log('RAW: ',raw);
    const bodyJson = await raw.json();
    result.raw = bodyJson;
    console.log('Body: ', bodyJson);
    if (bodyJson?.hasOwnProperty("rows") && Array.isArray(bodyJson.rows)) {
      result.records = bodyJson.rows;
    }
    if (bodyJson?.hasOwnProperty("rowCount")) {
      result.affected = bodyJson.rowCount;
    }
    if (!useStructuredResult) {
      return result.raw;
    }
    return result;
  }
}

