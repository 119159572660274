import {Component} from '@angular/core';
import {NgStyle} from "@angular/common";


@Component({
    selector: 'project-word-count',
    standalone: true,
    imports: [
        NgStyle
    ],
    templateUrl: './project-word-count.component.html'
})

export class ProjectWordCountComponent {

    constructor() {
    }
}
