<p-card *ngIf="comment" class="p-fluid">
    <ng-template pTemplate="content">
        <div class="field">
            <label for="comment">{{comment.authorRole}}: {{comment.authorScreenName}}</label>
            <textarea [disabled]="!isNew" pInputTextarea id="comment" [(ngModel)]="comment.comment" required autofocus rows="3" cols="20"></textarea>
            <small class="ng-dirty ng-invalid" *ngIf="submitted && (!comment.comment || comment.comment.length === 0)">You need to leave a comment.</small>
        </div>
        <p-accordion [multiple]="true" *ngIf="comment.replies?.length > 0" [activeIndex]="[comment.replies?.length-1]" (onOpen)="Reply.markViewed(comment.replies[$event.index],user)">
            <p-accordionTab [header]="reply.authorRole+': '+reply.authorScreenName" *ngFor="let reply of comment.replies">
                <p class="m-0">
                    {{ reply.comment }}
                </p>
            </p-accordionTab>
        </p-accordion>
        <div class="field" *ngIf="!isNew">
            <label for="reply">Reply</label>
            <textarea pInputTextarea id="reply" [(ngModel)]="baseReply.comment" required autofocus rows="3" cols="20"></textarea>
            <small class="ng-dirty ng-invalid" *ngIf="submitted && (!baseReply.comment || baseReply.comment.length === 0)">You have to write a reply</small>
        </div>
        <!--
            Reply goes here if it isn't a new comment.
            Note: We don't allow editing of comments once they are saved.
        -->
    </ng-template>
</p-card>
