<div class="card mb-0">
    <div class="flex justify-content-between mb-3">
        <div>
            <span class="block text-500 font-medium mb-3">Statistic</span>
            <div class="text-900 font-medium text-xl">Number</div>
        </div>
        <div [class]="'flex align-items-center justify-content-center bg-'+icolor+'-100 border-round'" [ngStyle]="{width: '2.5rem', height: '2.5rem'}">
            <i [class]="'pi pi-question text-'+icolor+'-500 text-xl'"></i>
        </div>
    </div>
    <span class="text-green-500 font-medium">Placeholder</span>
    <span class="text-500"> since . . .</span>
</div>
