import {Component, Input} from '@angular/core';
import {DropdownModule} from "primeng/dropdown";
import {InputTextModule} from "primeng/inputtext";
import {MultiSelectModule} from "primeng/multiselect";
import {PaginatorModule} from "primeng/paginator";
import {TooltipModule} from "primeng/tooltip";
import {ProjectDescriptor, ProjectDescriptorCategory} from "../../../../../API";

@Component({
  selector: 'project-descriptor-editor',
  standalone: true,
    imports: [
        DropdownModule,
        InputTextModule,
        MultiSelectModule,
        PaginatorModule,
        TooltipModule
    ],
  templateUrl: './project-descriptor-editor.component.html',
  styleUrl: './project-descriptor-editor.component.scss'
})
export class ProjectDescriptorEditorComponent {
    @Input() projectDescriptor!: ProjectDescriptor;
    @Input() category!: ProjectDescriptorCategory;
    constructor() {
    }



}


