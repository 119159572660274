import {AfterContentInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CheckboxModule} from "primeng/checkbox";
import {TooltipModule} from "primeng/tooltip";
import {NgIf} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {InputTextModule} from "primeng/inputtext";
import {PaginatorModule} from "primeng/paginator";
import {ProjectDescriptor, ProjectDescriptorCategory, ProjectDescriptorCategoryVariant} from "../../../../../API";
import {ProjectDescriptorEditorComponent} from "../project-descriptor-editor/project-descriptor-editor.component";
import {SplitButtonModule} from "primeng/splitbutton";
import {ConfirmationService, MenuItem, MessageService} from "primeng/api";
import {ProjectDescriptorType, ProjectDescriptorUse} from "../../../../api/enums";
import {ProgressService} from "../../../../service/progress.service";
import {RadioButtonModule} from "primeng/radiobutton";
import {
    DataService
} from "../../../../service/data.service";

@Component({
    selector: 'project-descriptor',
    standalone: true,
    imports: [
        CheckboxModule,
        TooltipModule,
        NgIf,
        FormsModule,
        InputTextModule,
        PaginatorModule,
        ProjectDescriptorEditorComponent,
        SplitButtonModule,
        RadioButtonModule
    ],
    templateUrl: './project-descriptor.component.html',
    styleUrl: './project-descriptor.component.scss'
})
export class ProjectDescriptorComponent implements OnInit, AfterContentInit {
    @Input() projectDescriptor!: ProjectDescriptor;
    @Input() descriptorCategory!: ProjectDescriptorCategory;
    @Input() editable: boolean = false;
    editing: boolean = false;
    editMenuItems: MenuItem[] = [
        {label: 'Undo Changes and Cancel Editing', icon: 'pi pi-fw pi-undo', command: (e) => this.cancelChanges()},
        {separator: true},
        {label: 'Permanently Delete this Item', icon: 'pi pi-fw pi-trash', command: (e) => this.confirmDelete()}
    ]

    constructor(private progressService: ProgressService, protected dataService: DataService, private confirmationService: ConfirmationService, private messageService: MessageService) {
    }

    @Input() use!: ProjectDescriptorUse;
    @Input() variant: ProjectDescriptorCategoryVariant;
    @Input() namespace!: string;

    ngOnInit(): void {
        this.initializeValue();
    }

    ngAfterContentInit() {
    }

    async updateProjectDescriptor(pd: ProjectDescriptor) {
        this.editing = false;
        await this.dataService.saveDescriptor(pd);
    }

    initializeValue() {
        if (!this.variant) {
            console.log('No Variant: ', this.descriptorCategory);
        }
    }


    private confirmDelete() {
        this.confirmationService.confirm({
            key: `confirmDelete`,
            target: new EventTarget,
            message: 'Are you sure that you want to proceed?',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.messageService.add({
                    severity: 'info',
                    summary: 'Confirmed',
                    detail: 'Removing this Project Descriptor from all projects.'
                });
                this.dataService.removeDescriptor(this.projectDescriptor);
            }
        });
    }

    private async cancelChanges() {
        this.progressService.blockWithMessage('Cancelling Changes');
        const {enUsLabel, enUsHelp} = await this.dataService.pullDescriptor(this.projectDescriptor.id);
        this.projectDescriptor.enUsLabel = enUsLabel;
        this.projectDescriptor.enUsHelp = enUsHelp;
        this.editing = false;
        this.progressService.unBlock();
    }
}
