import {Component} from '@angular/core';
import {NgStyle} from "@angular/common";


@Component({
    selector: 'user-count',
    standalone: true,
    imports: [
        NgStyle
    ],
    templateUrl: './user-count.component.html'
})

export class UserCountComponent {

    constructor() {
    }
}
