import {
  Column,
  Entity,
  Index,
  JoinColumn,
  ManyToOne,
  PrimaryGeneratedColumn,
} from "typeorm";
import { ProjectDescriptorCategory } from "./ProjectDescriptorCategory";

@Index("project_descriptor_category_label_pkey", ["id"], { unique: true })
@Entity("project_descriptor_category_variant")
export class ProjectDescriptorCategoryVariant {
  @PrimaryGeneratedColumn({ type: "bigint", name: "id" })
  id!: string;

  @Column("text", { name: "en_us_label" })
  enUsLabel!: string;

  @Column("text", { name: "en_us_help", nullable: true })
  enUsHelp!: string | null;

  @Column("text", { name: "uses", nullable: true, array: true })
  uses!: string[] | null;

  @Column("text", { name: "question_type", nullable: true })
  questionType!: string | null;

  @Column("text", { name: "tag_icon", nullable: true })
  tagIcon!: string | null;

  @Column("text", { name: "tag_color", nullable: true })
  tagColor!: string | null;

  @ManyToOne(
    () => ProjectDescriptorCategory,
    (projectDescriptorCategory) =>
      projectDescriptorCategory.projectDescriptorCategoryVariants,
    { lazy: true }
  )
  @JoinColumn([{ name: "category_id", referencedColumnName: "id" }])
  category!: Promise<ProjectDescriptorCategory>;
}
