import {Component, Input, OnInit} from '@angular/core';
import {DropdownModule} from "primeng/dropdown";
import {InputTextModule} from "primeng/inputtext";
import {MultiSelectModule} from "primeng/multiselect";
import {PaginatorModule} from "primeng/paginator";
import {TooltipModule} from "primeng/tooltip";
import {ProjectDescriptorCategory, ProjectDescriptorCategoryVariant} from "../../../../../API";
import {BetaReaderMatchUse, ProjectDescriptorType, ProjectDescriptorUse} from "../../../../api/enums";
import {ConfirmationService, MenuItemCommandEvent, MessageService} from "primeng/api";
import {SplitButtonModule} from "primeng/splitbutton";
import {DividerModule} from "primeng/divider";
import {NgIf, NgForOf} from "@angular/common";
import {RippleModule} from "primeng/ripple";
import {ProgressService} from "../../../../service/progress.service";
import {IconSelectorComponent} from "../../../icon-selector/icon-selector.component";
import {
    DataService
} from "../../../../service/data.service";

@Component({
  selector: 'project-descriptor-category-editor',
  standalone: true,
    imports: [
        DropdownModule,
        InputTextModule,
        MultiSelectModule,
        PaginatorModule,
        TooltipModule,
        SplitButtonModule,
        DividerModule,
        NgIf,
        RippleModule,
        NgForOf,
        IconSelectorComponent
    ],
  templateUrl: './project-descriptor-category-editor.component.html',
  styleUrl: './project-descriptor-category-editor.component.scss'
})
export class ProjectDescriptorCategoryEditorComponent implements OnInit {
    @Input() category!: ProjectDescriptorCategory;

    protected availableUseOptionsByVariant: string[][] = [];
    protected usesByLabelInEn: string[] = [];
    protected projectDescriptorTypes: string[] = Object.values(ProjectDescriptorType);
    protected projectDescriptorUses: string[] = Object.values(ProjectDescriptorUse);
    protected betaReaderMatchUses: string[] = Object.values(BetaReaderMatchUse);


    constructor(private progressService: ProgressService, private dataService: DataService, private confirmationService: ConfirmationService, private messageService: MessageService) {
    }

    ngOnInit() {
        this.recalculateAvailableUses();
    }

    protected recalculateAvailableUses() {
        for (const variant of this.category.variants.items) {
            this.availableUseOptionsByVariant[variant.id] = this.availableUses(variant);
            this.usesByLabelInEn[variant.id] = this.usesInEn(variant.uses);
        }
        this.allUsesCovered = this.availableUses(null).length === 0;
        console.log('AVAILABLE USES (LEFT)',this.availableUses(null));

    }

    private availableUses(cvariant: ProjectDescriptorCategoryVariant) {
        const filter: string[][] = [];
        for (const variant of this.category.variants.items) {
            if (!cvariant || cvariant.id !== variant.id) {
                filter.push(variant.uses);
            }
        }
        return this.filteredArray(
            this.category.uses,
            filter
        );
    }

    private filteredArray<Type>(full: Type[], toRemove: Type[][]): Type[] {
        for (const a of toRemove) {
            full = full.filter(x => !a.includes(x));
        }
        return full;
    }

    private usesInEn(uses: string[]): string {
        // Now let's build a cool list in plain english
        //  Note: not easy for i18n, but this is only used in admin
        let ret: string = "";
        switch (uses.length) {
            case 0:
                ret = '(unused)';
                break;
            case 1:
                ret = 'for ' + uses[0];
                break;
            default:
                ret = 'for ';
                for (let i = 0; i < uses.length - 1; i++) {
                    ret = ret + uses[i] + ', ';
                }
                ret = ret +
                    ' and ' + uses[uses.length - 1];
        }
        return ret;
    }

    confirmDelete(event: MenuItemCommandEvent) {
        this.confirmationService.confirm({
            key: `confirmDelete`,
            target: new EventTarget,
            message: 'Are you sure that you want to delete this category?',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'Removing Category from All Projects' });
                this.dataService.removeCategory(this.category);
            }
        });
    }

    confirmDeleteVariant(event: MenuItemCommandEvent, variant: ProjectDescriptorCategoryVariant) {
        this.confirmationService.confirm({
            key: `confirmDelete`,
            target: new EventTarget,
            message: 'Are you sure that you want to delete this variant?',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'Removing variant from category' });
                this.dataService.removeVariant(variant);
                this.category.variants.items = this.category.variants.items.filter(l => l.id !== variant.id);
            }
        });
    }

    async addVariant() {
        this.progressService.blockWithMessage('Adding Label');
        this.category.variants.items.push(await this.dataService.addEmptyVariant(this.category));
        this.progressService.unBlock();
    }

    protected readonly ProjectDescriptorType = ProjectDescriptorType;
    allUsesCovered: boolean;

}


