import {ProjectDescriptorCategory as ProjectDescriptorCategoryOLD, ProjectDescriptorCategoryVariant as ProjectDescriptorCategoryVariantOLD} from "../API";
import {ProjectDescriptorUse} from "./api/enums";
import {DataSource} from "typeorm";
import {fetchAuthSession} from "aws-amplify/auth";
import {DocumentSection} from "./service/db/entities/DocumentSection";
import {Project} from "./service/db/entities/Project";

import {environment} from "../environments/environment";
import {PlatformUser} from "./service/db/entities/PlatformUser";
import {WriterProfile} from "./service/db/entities/WriterProfile";
import {BetaReaderProject} from "./service/db/entities/BetaReaderProject";
import {BetaReaderProfile} from "./service/db/entities/BetaReaderProfile";
import {BetaReaderProfileSelection} from "./service/db/entities/BetaReaderProfileSelection";
import {ProjectProfileSelection} from "./service/db/entities/ProjectProfileSelection";
import {ProjectDescriptor} from "./service/db/entities/ProjectDescriptor";
import {ProjectDescriptorCategory} from "./service/db/entities/ProjectDescriptorCategory";
import {ProjectDescriptorCategoryVariant} from "./service/db/entities/ProjectDescriptorCategoryVariant";

import {
    ProjectDescriptorCategoryComponent
} from "./components/project-descriptor/project-descriptor-categories/category/project-descriptor-category.component";

export function getVariant(c: ProjectDescriptorCategoryOLD, use: ProjectDescriptorUse): ProjectDescriptorCategoryVariantOLD {
    if (!c.variants || !c.variants.items || c.variants.items.length === 0) {
        this.category.variants.items.push({
            __typename: "ProjectDescriptorCategoryVariant",
            id: null,
            enUsLabel: 'New Label',
            enUsHelp: '',
            uses: [],
            categoryId: this.category.id
        });
    }
    if (!use) {
        return c.variants.items[0];
    }
    for (const variant of c.variants.items) {
        if (variant.uses.includes(use.valueOf())) {
            return variant;
        }
    }
    return c.variants.items[0];
}

export class ORM {
    private static dataSource: DataSource;

    public static async getORMDatasource(): Promise<DataSource> {
        //Initialize a connection for this user if it doesn't exist.
        if (!this.dataSource?.isInitialized) {

            let apiKey: string = (await fetchAuthSession()).tokens.idToken.toString();

            this.dataSource = new DataSource({
                type: "rng-remote-postgres",
                user: apiKey,
                remoteURL: environment.RNGRemoteLambda,
                logging: environment.dbLogging,
                entities: [
                    DocumentSection,
                    Project,
                    PlatformUser,
                    WriterProfile,
                    BetaReaderProject,
                    BetaReaderProfile,
                    BetaReaderProfileSelection,
                    ProjectProfileSelection,
                    ProjectDescriptor,
                    ProjectDescriptorCategory,
                    ProjectDescriptorCategoryVariant,

                ]
            });
            await this.dataSource.initialize();
            console.log("Data Source has been initialized!", this.dataSource);
        }
        return this.dataSource;
    }
}
