/**
 * This class stores up and down queries needed for migrations functionality.
 */
export class SqlInMemory {
  constructor() {
    this.upQueries = [];
    this.downQueries = [];
  }
}

