import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ButtonModule} from "primeng/button";
import {AsyncPipe, CurrencyPipe, DatePipe} from "@angular/common";
import {DropdownModule} from "primeng/dropdown";
import {InputTextModule} from "primeng/inputtext";
import {MultiSelectModule} from "primeng/multiselect";
import {ProgressBarModule} from "primeng/progressbar";
import {MenuItem, SharedModule} from "primeng/api";
import {SliderModule} from "primeng/slider";
import {TableModule} from "primeng/table";
import {OrderListModule} from "primeng/orderlist";
import {CheckboxModule} from "primeng/checkbox";
import {MenuModule} from "primeng/menu";
import {InplaceModule} from "primeng/inplace";
import {FormsModule} from "@angular/forms";
import {ProjectDescriptorEditorComponent} from "./project-descriptor-entries/project-descriptor-editor/project-descriptor-editor.component";
import {ProjectDescriptorComponent} from "./project-descriptor-entries/project-descriptor/project-descriptor.component";
import {NgIf, NgForOf} from "@angular/common";
import {DataViewModule} from "primeng/dataview";
import {CardModule} from "primeng/card";
import {BlockUIModule} from "primeng/blockui";
import {SplitButtonModule} from "primeng/splitbutton";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {ToastModule} from "primeng/toast";
import {ConfirmationService} from 'primeng/api';
import {SkeletonModule} from "primeng/skeleton";
import {ProjectDescriptor, ProjectDescriptorCategory} from "../../../API";
import {
    ProjectDescriptorCategoryComponent
} from "./project-descriptor-categories/category/project-descriptor-category.component";
import {ProjectDescriptorUse} from "../../api/enums";
import {ProgressService} from "../../service/progress.service";
import {getVariant} from "../../utils";
import { Subscription } from 'rxjs';
import {DataService} from "../../service/data.service";
@Component({
  selector: 'project-descriptor-panel',
  standalone: true,
    imports: [
        ButtonModule,
        CurrencyPipe,
        DatePipe,
        DropdownModule,
        InputTextModule,
        MultiSelectModule,
        ProgressBarModule,
        SharedModule,
        SliderModule,
        TableModule,
        AsyncPipe,
        OrderListModule,
        CheckboxModule,
        MenuModule,
        InplaceModule,
        FormsModule,
        ProjectDescriptorEditorComponent,
        ProjectDescriptorComponent,
        NgIf,
        NgForOf,
        DataViewModule,
        CardModule,
        BlockUIModule,
        SplitButtonModule,
        ConfirmDialogModule,
        ToastModule,
        SkeletonModule,
        ProjectDescriptorCategoryComponent


    ],
  templateUrl: './project-descriptor-panel.component.html',
  styleUrl: './project-descriptor-panel.component.scss'
})
export class ProjectDescriptorPanelComponent implements OnInit, OnDestroy {
    @Input() editable: boolean = false;
    @Input() use: ProjectDescriptorUse = null;
    @Input() namespace!: string;

    reorder: boolean = false;
    editMenuItems: MenuItem[];
    protected categories: ProjectDescriptorCategory[] = [];
    protected viewDropDown: {name: string, value: ProjectDescriptorUse}[];

    constructor(private progressService: ProgressService, private confirmationService: ConfirmationService, private dataService: DataService) {
        this.viewDropDown = [];
        this.viewDropDown.push({
            name: 'Show all',
            value: null
        });
        for (const use of Object.values(ProjectDescriptorUse)) {
            this.viewDropDown.push({
                name: 'View as ' + use.valueOf(),
                value: use
            });
        }

        this.editMenuItems = [
            {label: 'Add New Category', icon: 'pi pi-fw pi-plus', command: () => this.addCategory()},
            {label: 'Reorder Categories', icon: 'pi pi-fw pi-sort-alt', command: () => this.reorder = true},
        ];

    }

    ngOnInit(): void {
        this.subscriptions.push(this.dataService.projectDescriptorCategories.subscribe(pdc => {
            this.categories = pdc;
            console.log('Category loader in project-descriptor-panel: ', this.categories);
        }));
    }

    private subscriptions: Subscription[] = [];
    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe())
    }

    async addCategory() {
        this.progressService.blockWithMessage("Adding Category");
        const id = await this.dataService.addEmptyCategory();
        this.progressService.unBlock();
    }

    async saveAfterReorder() {
        this.reorder = false;
        await this.dataService.saveCategoriesAfterReorder(this.categories);
    }

    protected readonly getVariant = getVariant;
}
