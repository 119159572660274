import {
  Column,
  Entity,
  Index,
  OneToMany,
  PrimaryGeneratedColumn,
} from "typeorm";
import { ProjectDescriptor } from "./ProjectDescriptor";
import { ProjectDescriptorCategoryVariant } from "./ProjectDescriptorCategoryVariant";

@Index("project_descriptor_category_pkey", ["id"], { unique: true })
@Entity("project_descriptor_category")
export class ProjectDescriptorCategory {
  @PrimaryGeneratedColumn({ type: "bigint", name: "id" })
  id!: string;

  @Column("timestamp with time zone", { name: "deleted_at", nullable: true })
  deletedAt!: Date | null;

  @Column("text", { name: "deleted_by", nullable: true })
  deletedBy!: string | null;

  @Column("integer", { name: "display_order" })
  displayOrder!: number;

  @Column("text", {
    name: "beta_reader_match_use",
    default: () => "'DO_NOT_USE_TO_MATCH'",
  })
  betaReaderMatchUse!: string;

  @Column("text", { name: "uses", nullable: true, array: true })
  uses!: string[] | null;

  @OneToMany(
    () => ProjectDescriptor,
    (projectDescriptor) => projectDescriptor.category,
    { lazy: true }
  )
  projectDescriptors!: Promise<ProjectDescriptor[]>;

  @OneToMany(
    () => ProjectDescriptorCategoryVariant,
    (projectDescriptorCategoryVariant) =>
      projectDescriptorCategoryVariant.category,
    { lazy: true }
  )
  projectDescriptorCategoryVariants!: Promise<
    ProjectDescriptorCategoryVariant[]
  >;
}
