import {
    Component,
    ElementRef,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    signal,
    ViewChild,
    WritableSignal
} from '@angular/core';
import {environment} from "../../../environments/environment";
import {PlatformUser, Project} from "../../../API";
import {NgClass, NgForOf, NgIf} from "@angular/common";
import {ProjectProfileComponent} from "../writer-dashboard/project-profile/project-profile.component";
import {
    WriterResourceMatchesComponent
} from "../writer-dashboard/writer-resource-matches/writer-resource-matches.component";
import {ButtonModule} from "primeng/button";
import {DataService} from "../../service/data.service";
import {DialogModule} from "primeng/dialog";
import {MessageService} from "primeng/api";
import {BadgeModule} from "primeng/badge";

import {ProgressService} from "../../service/progress.service";
import {Subscription} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {PaginatorModule} from "primeng/paginator";
import Quill from "quill";
import {RNGComments, Comment, Reply, CommentView} from "./RNG_Comments";
import {QuillEditorComponent} from "ngx-quill";
import {InputSwitchModule} from "primeng/inputswitch";
import {InputTextModule} from "primeng/inputtext";
import {InputTextareaModule} from "primeng/inputtextarea";
import {RadioButtonModule} from "primeng/radiobutton";
import {Ripple} from "primeng/ripple";
import {CardModule} from "primeng/card";
import {co} from "@fullcalendar/core/internal-common";
import {AccordionModule, AccordionTabOpenEvent} from "primeng/accordion";

@Component({
    selector: 'comment',
    standalone: true,
    templateUrl: './comment.component.html',
    imports: [
        NgIf,
        ProjectProfileComponent,
        WriterResourceMatchesComponent,
        ButtonModule,
        DialogModule,
        BadgeModule,
        NgClass,
        NgForOf,
        PaginatorModule,
        QuillEditorComponent,
        InputSwitchModule,
        InputTextModule,
        InputTextareaModule,
        RadioButtonModule,
        Ripple,
        CardModule,
        AccordionModule
    ],
    styleUrl: './comment.component.scss'
})
export class CommentComponent {

    @Input() user: PlatformUser;

    //Need to know what role I have on this specific project
    @Input() comment: Comment;
    @Input() isNew: boolean;
    @Input() baseReply: Reply;


    constructor(private dataService: DataService) {
    }

    submitted: boolean =  false;
    protected readonly Reply = Reply;
}
